import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS, USER_STATUS } from 'utils/constants';
import { StrategyGoal } from 'utils/types/strategyGoals';
// import { UserOption } from 'utils/customTypes';
import { RootState } from 'state/store';
import strategyGoalsAPI from './StrategyGoalsAPI';

interface StrategyGoalsState {
  status: Status;
  value: StrategyGoal[];
  owners: any[];
}

/* ============================= INITIAL STATE ============================== */

const initialState: StrategyGoalsState = {
  value: [] as StrategyGoal[],
  status: SLICE_STATUS.IDLE,
  owners: [],
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const fetchStrategyGoals = createAsyncThunk(
  'strategyGoals/FETCH_STRATEGY_GOALS',
  async () => {
    const response = await strategyGoalsAPI.fetchStrategyGoals();
    return response?.data;
  }
);

export const fetchStrategyOwners = createAsyncThunk(
  'strategyGoals/FETCH_STRATEGY_OWNERS',
  async () => {
    const response = await strategyGoalsAPI.fetchOwnersList();
    return response?.data;
  }
);
/* ================================= REDUCER ================================ */
const strategyGoalsSlice = createSlice({
  name: 'strategyGoals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStrategyGoals.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchStrategyGoals.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchStrategyGoals.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchStrategyOwners.fulfilled, (state, action) => {
        state.owners = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectStrategyGoals = (state: RootState) =>
  state.strategyGoals.value;

export const selectStrategyGoalsStatus = (state: RootState) =>
  state.strategyGoals.status;

export const selectStrategyGoalsOwners = (state: RootState) =>
  state.strategyGoals.owners.map((owner) => ({
    label: owner.name,
    value: owner.id,
    avatar: {
      imageSrc: owner.avatar,
      initial: owner.initials,
      name: owner.name,
    },
    disabled:
      owner.status === USER_STATUS.REGISTERED_DISABLED ||
      owner.status === USER_STATUS.INVITED_DISABLED,
  }));

export const selectActiveGoalsOwners = createSelector(
  [selectStrategyGoalsOwners],
  (owners: any[]) => {
    return owners.filter((owner) => !owner.disabled);
  }
);

export default strategyGoalsSlice.reducer;
