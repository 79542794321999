import { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Tabs, Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import {
  selectTeamRequests,
  selectUserRequests,
  setOrder,
  getAllRequests,
  getUserRequests,
  exportRequests,
  exportRequestsByForm,
  searchByTitle,
} from 'state/Requests/requestSlice';
import {
  selectUserType,
  selectUser,
  selectOrganizationId,
} from 'state/User/userSlice';
import { selectOrganizationSettings } from 'state/Organization/organizationSlice';
import { getForms, filteredFormsOptions } from 'state/Forms/formSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { selectRequestsPortalName } from 'state/Organization/organizationSlice';
import {
  PATHS,
  USER_TYPES,
  SETTINGS_FILTERS,
  REQUESTS_TABLE,
} from 'utils/constants';
import { FormOption } from 'utils/customTypes';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import MyRequestsTable from './components/MyRequestsTable';
import TeamRequestsTable from './components/TeamRequestsTable';
import { fetchProjects } from 'state/Projects/projectsSlice';
import ActiveRequestsTable from './components/ActiveRequestsTable/ActiveRequestsTable';
import useRequestTabsMapping, {
  RequestTabsKeys,
} from './hooks/useRequestTabsMapping';

interface RequestTab {
  label: string;
  content: false | JSX.Element;
}

const RequestsListPage = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');
  const allRequests = useSelector(selectTeamRequests);
  const userRequests = useSelector(selectUserRequests);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const userType = useSelector(selectUserType);
  const isBusinessUser = useMemo(
    () => userType === USER_TYPES.BUSINESS,
    [userType]
  );
  const availableTabs = useRequestTabsMapping(isBusinessUser);
  const [tabIndex, setTabIndex] = useState<number>(
    activeTab ? availableTabs[activeTab as RequestTabsKeys]?.index || 0 : 0
  );
  const formOptionsSelector: FormOption[] = useSelector(filteredFormsOptions);
  const organizationId = useSelector(selectOrganizationId);
  const currentUser = useSelector(selectUser);
  const organizationSettings = useSelector(selectOrganizationSettings);

  const showTeamAndActiveTabs = useMemo<boolean>(() => {
    const isInBusinessTeam: boolean = !!get(currentUser, 'businessTeam');
    const isTeamTabEnabled: boolean = get(
      organizationSettings,
      'teamRequestsTab',
      false
    );

    return (
      (isBusinessUser && isInBusinessTeam && isTeamTabEnabled) ||
      !isBusinessUser
    );
  }, [currentUser, isBusinessUser, organizationSettings]);
  const portalName = useSelector(selectRequestsPortalName);

  const createNewRequestLink = useMemo(() => {
    if (!isEmpty(formOptionsSelector) && formOptionsSelector[0].value) {
      return `${PATHS.REQUEST_PAGE}/${formOptionsSelector[0].value}`;
    }
    return PATHS.REQUEST_PAGE;
  }, [formOptionsSelector]);

  useEffect(() => {
    dispatch(getAllRequests());
    dispatch(getUserRequests());
    dispatch(fetchProjects());
    if (organizationId) {
      dispatch(getForms({ organizationId, published: true }));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.TEAM_REQUESTS_TABLE));
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.MY_REQUESTS_TABLE));
    dispatch(
      searchByTitle({ searchParam: '', table: REQUESTS_TABLE.TEAM_REQUESTS })
    );
    dispatch(
      searchByTitle({ searchParam: '', table: REQUESTS_TABLE.MY_REQUESTS })
    );
  }, [dispatch]);

  const handleSort = (orderByParam: string, order: 'desc' | 'asc') => {
    dispatch(setOrder({ order, orderBy: orderByParam }));
  };

  const onExportHandler = useCallback(
    async (selectedRequests: string[], callback: () => void) => {
      await dispatch(exportRequests(selectedRequests));
      callback();
    },
    [dispatch]
  );

  const onExportByFormHandler = async (
    selectedRequests: string[],
    callback: () => void
  ) => {
    await dispatch(exportRequestsByForm(selectedRequests));
    callback();
  };

  const tabContents: Record<RequestTabsKeys, JSX.Element> = {
    myItems: (
      <MyRequestsTable
        handleSort={handleSort}
        onExportRequests={onExportHandler}
        onExportRequestsByForm={onExportByFormHandler}
        setModalOpen={setModalOpen}
        setModalProps={setModalProps}
        userRequests={userRequests}
      />
    ),
    teamItems: (
      <TeamRequestsTable
        allRequests={allRequests}
        handleSort={handleSort}
        onExportRequests={onExportHandler}
        onExportRequestsByForm={onExportByFormHandler}
        setModalOpen={setModalOpen}
        setModalProps={setModalProps}
      />
    ),
    activeItems: <ActiveRequestsTable />,
  };

  const requestTabs = Object.keys(availableTabs).reduce(
    (requestTabs, currentTabKey) => {
      const tabKey = currentTabKey as RequestTabsKeys;
      const tab = availableTabs[tabKey];

      if (!tab) {
        return requestTabs;
      } else {
        const availableTab = {
          label: tab.label,
          content: tabIndex === tab.index && tabContents[tabKey],
        };
        return requestTabs.concat(availableTab);
      }
    },
    [] as RequestTab[]
  );

  return (
    <div className='flex flex-col h-full'>
      <Modal
        isOpen={modalOpen}
        aria-label={intl.get('REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.TITLE')}
        {...modalProps}
      />
      <PageTitle
        titleComponent={portalName}
        headerChildren={
          <Link to={createNewRequestLink} data-cy='add-request-button'>
            <Button>{intl.get('REQUESTS_LIST_PAGE.ADD_REQUEST_BUTTON')}</Button>
          </Link>
        }
      />
      {showTeamAndActiveTabs ? (
        <Tabs
          className='px-6 pb-20 flex-grow'
          data-cy='request-tabs'
          data={requestTabs}
          defaultIndex={tabIndex}
          tabListProps={{ className: 'max-w-sm mb-4' }}
          tabPanelsProps={{ className: 'max-h-page-body overflow-y-hidden' }}
          type='tab'
          onChange={(index: number) => {
            setTabIndex(index);
            window.history.replaceState(
              {},
              '',
              `${window.location.pathname}?activeTab=${
                Object.keys(availableTabs)[index]
              }`
            );
          }}
        />
      ) : (
        <div className='mt-4 px-6 pb-20 overflow-y-auto flex-grow'>
          <Typography
            variant='h6'
            weight='medium'
            className='text-neutral-dark mb-5'
            data-cy='my-requests-title'
          >
            {intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS')}
          </Typography>
          (
          <MyRequestsTable
            handleSort={handleSort}
            onExportRequests={onExportHandler}
            onExportRequestsByForm={onExportByFormHandler}
            setModalOpen={setModalOpen}
            setModalProps={setModalProps}
            userRequests={userRequests}
          />
          )
        </div>
      )}
    </div>
  );
};

export default RequestsListPage;
