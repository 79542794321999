import { FC } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  LinkedTask,
  LinkedTaskPermissions,
} from 'types/store/taskDependencies';
import TaskStatusTag from 'Molecules/TaskStatusTag/TaskStatusTag';
import CardTitle from './CardTitle/CardTitle';
import CardTitleLink from './CardTitleLink/CardTitleLink';
import DateRange from './DateRange/DateRange';
import RemoveDependencyButton from './RemoveDependencyButton/RemoveDependencyButton';

interface LinkedTaskCardProps {
  linkedTask: LinkedTask;
  route: string;
  onRemoveDependency: (linkedTask: LinkedTask) => void;
  permissions?: LinkedTaskPermissions;
  isTaskOverDue?: boolean;
  overlapingDates?: boolean;
}

const LinkedTaskCard: FC<LinkedTaskCardProps> = ({
  linkedTask,
  route,
  onRemoveDependency,
  permissions = {
    canDelete: false,
    canEdit: false,
    canView: false,
  },
  isTaskOverDue = false,
  overlapingDates = false,
}) => {
  return (
    <div
      aria-label={`linked-task-${linkedTask.id}`}
      className={tailwindOverride(
        'w-full flex justify-between',
        'px-6 py-4 rounded bg-neutral-white',
        'border border-neutral-lighter-two',
        { 'bg-neutral-lightest-two': !permissions.canView }
      )}
    >
      {permissions.canView && (
        <CardTitleLink
          taskDisplayId={linkedTask.displayId}
          taskName={linkedTask.name}
          route={route}
          disabled={linkedTask.disabled}
        />
      )}
      {!permissions.canView && (
        <CardTitle
          taskDisplayId={linkedTask.displayId}
          taskName={linkedTask.name}
        />
      )}
      <div className='flex items-center gap-x-4'>
        <DateRange
          startDate={linkedTask.startDate}
          dueDate={linkedTask.dueDate}
          isTaskOverDue={isTaskOverDue}
          overlapingDates={overlapingDates}
        />
        <TaskStatusTag status={linkedTask.status} />
        {permissions.canDelete && (
          <RemoveDependencyButton
            removeDependency={() => onRemoveDependency(linkedTask)}
          />
        )}
      </div>
    </div>
  );
};

export default LinkedTaskCard;
