import { FC, useState, useCallback } from 'react';
import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import { fetchAvailableTasks } from 'api/taskDependencies';
import useAddTaskDependencies from './useAddTaskDependencies';
import AddDependencyForm from 'Organisms/TaskDependencies/AddDependencyForm/AddDependencyForm';
import ActionsButtons from './ActionsButtons';

interface AddDependenciesProps {
  projectId: string;
  taskId: string;
}
const AddDependencies: FC<AddDependenciesProps> = ({ projectId, taskId }) => {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const toggleDisplayForm = useCallback(
    () => setDisplayForm((prev) => !prev),
    []
  );

  const {
    canSubmit,
    submitTaskDependencies,
    updateDependencies,
    updateRelationshipType,
  } = useAddTaskDependencies({
    taskId,
    onFail: toggleDisplayForm,
  });

  const searchTasks = useCallback(
    async (taskName?: string) => {
      const response = await fetchAvailableTasks({
        projectId,
        taskName,
        taskId,
      });
      return response;
    },
    [projectId, taskId]
  );

  return (
    <>
      {!displayForm && (
        <Button
          iconName='add-circle'
          variant='tertiary'
          className='self-start'
          onClick={toggleDisplayForm}
        >
          {intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.ADD_DEPENDENCY')}
        </Button>
      )}
      {displayForm && (
        <AddDependencyForm
          setDependencyRelationship={updateRelationshipType}
          setDependencyTasks={updateDependencies}
          onSearchTasks={searchTasks}
          className='gap-x-6'
          actionButtons={
            <ActionsButtons
              onCancel={toggleDisplayForm}
              onAdd={submitTaskDependencies}
              shouldEnableAddButton={canSubmit}
            />
          }
        />
      )}
    </>
  );
};

export default AddDependencies;
