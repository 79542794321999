import { useState, useEffect, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@getsynapse/design-system';
import { getCurrentProjectData } from 'state/Project/projectSlice';
import {
  fetchProjectParticipantsAllocations,
  getProjectResourceAllocation,
  fetchProjectCollaboratorsAllocations,
  setAllocationsStatus,
  getAllocationsStatus,
} from 'state/ResourceAllocation/resourceAllocation';
import { exportCsv } from 'state/Projects/projectsSlice';
import {
  formatResourceAllocationSections,
  generateTableHeaders,
} from './helpers';
import {
  SLICE_STATUS,
  PROJECT_USER_ACTIONS,
  PROJECT_STATUS,
  USER_TYPES,
} from 'utils/constants';
import {
  Week,
  generateCsvHeaders,
  generateCsvData,
  formatUsersDataForCsvExport,
  generateCsvFileName,
} from 'Organisms/CapacityAllocationTable/helpers';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import useGetProjectReadOnly from 'Pages/ProjectPage/hooks/useGetProjectReadOnly';
import AddParticipantModal from '../../components/AddParticipantModal/AddParticipantModal';
import AdjustAllocationModal from '../../components/AdjustAllocationModal/AdjustAllocationModal';
import CapacityAllocationTable from 'Organisms/CapacityAllocationTable/CapacityAllocationTable';
import SkeletonLoading from 'Organisms/CapacityAllocationTable/components/SkeletonLoading';
import UpdateParticipantAssignmentModal from '../../components/UpdateParticipantAssignmentModal/UpdateParticipantAssignmentModal';
import { selectUserType, selectUserId } from 'state/User/userSlice';

const ResourceAllocation = () => {
  const dispatch = useDispatch();
  const projectData = useSelector(getCurrentProjectData);
  const allocationStatus = useSelector(getAllocationsStatus);
  const rawSections = useSelector(getProjectResourceAllocation);
  const { canUser } = useUserPermissionsContext();
  const userType = useSelector(selectUserType);
  const currentUserId = useSelector(selectUserId);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;
  const isCurrentFacilitatorUserProjectOwner =
    isFacilitator && projectData.owners?.includes(currentUserId);
  const isReadOnly =
    useGetProjectReadOnly(projectData.status, projectData.is_archived) ||
    (isFacilitator && !isCurrentFacilitatorUserProjectOwner);
  const formattedSections = useMemo(
    () => formatResourceAllocationSections(rawSections),
    [rawSections]
  );
  const headers = useMemo(() => generateTableHeaders(), []);
  const [displayAddParticipantModal, setDisplayAddParticipantModal] =
    useState(false);

  const init = useCallback(async () => {
    await dispatch(fetchProjectParticipantsAllocations(projectData.id));
    await dispatch(fetchProjectCollaboratorsAllocations(projectData.id));
  }, [dispatch, projectData.id]);

  useEffect(() => {
    dispatch(setAllocationsStatus(SLICE_STATUS.LOADING));
    if (projectData.id) {
      init();
    }
    setTimeout(() => dispatch(setAllocationsStatus(SLICE_STATUS.IDLE)), 1500);
  }, [projectData.id, init, dispatch]);

  const handleExportCsv = (selectedUsers: string[], weeksArray: Week[]) => {
    const fileName = generateCsvFileName(projectData);
    const users = formatUsersDataForCsvExport(selectedUsers, rawSections);
    const csvHeaders = generateCsvHeaders(weeksArray);
    const csvData = generateCsvData(weeksArray, users);
    dispatch(
      exportCsv({
        csvHeaders,
        csvData,
        fileName,
      })
    );
  };

  return (
    <div className='pb-6'>
      <AddParticipantModal
        isModalOpen={displayAddParticipantModal}
        onCloseModal={() => setDisplayAddParticipantModal(false)}
      />
      <AdjustAllocationModal />
      <UpdateParticipantAssignmentModal />
      {canUser(PROJECT_USER_ACTIONS.ADD_PARTICIPANT) && (
        <div className='absolute top-0 right-0'>
          <Button
            className='mr-6'
            onClick={() => setDisplayAddParticipantModal(true)}
            data-cy='add-participant-button'
            disabled={
              isReadOnly || projectData.status === PROJECT_STATUS.CANCELED
            }
          >
            {intl.get('PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.TITLE')}
          </Button>
        </div>
      )}
      {allocationStatus === SLICE_STATUS.LOADING && <SkeletonLoading />}
      {allocationStatus === SLICE_STATUS.IDLE && (
        <CapacityAllocationTable
          headers={headers}
          sections={formattedSections}
          startDate={new Date(projectData.startDate!)}
          endDate={new Date(projectData.targetCompletionDate!)}
          onExportUsers={handleExportCsv}
        />
      )}
    </div>
  );
};

export default ResourceAllocation;
