import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';

export enum RequestTabsKeys {
  ACTIVE_ITEMS = 'activeItems',
  TEAM_ITEMS = 'teamItems',
  MY_ITEMS = 'myItems',
}
type RequestTabsIndices = 0 | 1 | 2;
interface RequestTabValues {
  label: string;
  index: RequestTabsIndices;
}
type RequestTabsMapping = Partial<Record<RequestTabsKeys, RequestTabValues>>;

const useRequestTabsMapping = (isBusinessUser: boolean): RequestTabsMapping => {
  const { intakeActiveTab = false } = useFlags();

  const BUSINESS_USER_TABS_MAPPING = useMemo<RequestTabsMapping>(() => {
    if (intakeActiveTab) {
      return {
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 0,
        },
        activeItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.ACTIVE_REQUESTS'),
          index: 1,
        },
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 2,
        },
      };
    } else {
      return {
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 0,
        },
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 1,
        },
      };
    }
  }, [intakeActiveTab]);

  const NON_BUSINESS_USER_TABS_MAPPING = useMemo<RequestTabsMapping>(() => {
    if (intakeActiveTab) {
      return {
        activeItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.ACTIVE_REQUESTS'),
          index: 0,
        },
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 1,
        },
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 2,
        },
      };
    } else {
      return {
        teamItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.TEAM_REQUESTS'),
          index: 0,
        },
        myItems: {
          label: intl.get('REQUESTS_LIST_PAGE.SUB_NAV.MY_REQUESTS'),
          index: 1,
        },
      };
    }
  }, [intakeActiveTab]);

  const availableTabs: RequestTabsMapping = isBusinessUser
    ? BUSINESS_USER_TABS_MAPPING
    : NON_BUSINESS_USER_TABS_MAPPING;

  return availableTabs;
};

export default useRequestTabsMapping;
