import { FC, useState } from 'react';
import intl from 'react-intl-universal';
import { Option } from 'utils/customTypes';
import { FormItem, Dropdown } from '@getsynapse/design-system';
import { RelationshipType } from 'types/store/taskDependencies';
import RelationshipTypeOption, {
  RelationshipTypeListItem,
} from './RelationshipTypeOption';

interface RelationshipTypePickerProps {
  onSelectRelationshipType: (relationshipType: RelationshipType) => void;
  className?: string;
}

const RelationshipTypePicker: FC<RelationshipTypePickerProps> = ({
  onSelectRelationshipType,
  className,
}) => {
  const [selectedRelationshipType, setSelectedRelationshipType] =
    useState<RelationshipTypeListItem>({
      value: RelationshipType.DependsOn,
      label: intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDS_ON'),
    });

  const options: RelationshipTypeListItem[] = [
    {
      value: RelationshipType.DependsOn,
      label: intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDS_ON'),
    },
    {
      value: RelationshipType.Blocks,
      label: intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.BLOCKS'),
      tootip: intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.BLOCKS_TOOLTIP'),
    },
  ];

  const handleSelectRelationshipType = (
    relationshipType: RelationshipTypeListItem
  ) => {
    setSelectedRelationshipType(relationshipType);
    onSelectRelationshipType(relationshipType.value as RelationshipType);
  };

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.RELATIONSHIP_TYPE')}
      labelProps={{ required: true }}
      className={className}
    >
      <Dropdown
        options={options}
        values={[selectedRelationshipType]}
        onChange={handleSelectRelationshipType}
        renderOption={(
          option: Option,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => (
          <RelationshipTypeOption
            option={option}
            isSelected={isSelected}
            selectOption={selectOption}
            className={className}
            {...otherProps}
          />
        )}
      />
    </FormItem>
  );
};

export default RelationshipTypePicker;
