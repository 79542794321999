import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { Option } from 'utils/customTypes';
import {
  fetchTeamTasks,
  selectTasks,
  setSearchFilter,
} from 'state/ProjectTasksList/teamTasks/teamTasksSlice';
import TasksPicker from './TasksPicker';

interface LinkV2TasksProps {
  projectId: string;
  selectedTasks: Option[];
  setSelectedTasks: (tasks: Option[]) => void;
}

const LinkV2Tasks: FC<LinkV2TasksProps> = ({
  projectId,
  selectedTasks,
  setSelectedTasks,
}) => {
  const dispatch = useDispatch();
  const tasks = useSelector(selectTasks);

  const debouncedFilterChangeHandler = useRef(
    debounce((name: string) => {
      dispatch(setSearchFilter(name));
      dispatch(fetchTeamTasks({ projectId }));
    }, 500)
  );

  const tasksOptions = useMemo(() => {
    return tasks.map((task) => {
      return {
        label: task.name,
        value: task.id,
      };
    });
  }, [tasks]);

  useEffect(() => {
    dispatch(fetchTeamTasks({ projectId }));
  }, [dispatch, projectId]);

  return (
    <TasksPicker
      tasksOptions={tasksOptions}
      selectedTasks={selectedTasks}
      onSelectTasks={setSelectedTasks}
      onFilterChange={(name: string) =>
        debouncedFilterChangeHandler.current(name)
      }
    />
  );
};

export default LinkV2Tasks;
