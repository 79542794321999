import { FC, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import {
  selectDependencyToRemove,
  setDependencyToRemove,
  removeTaskDependencies,
} from 'state/TaskDependencies/TaskDependenciesSlice';
import {
  selectTaskDisplayId,
  selectTaskName,
} from 'state/TaskDetail/taskSlice';
import useModal from 'Hooks/useModal';
import RemoveDependencyIcon from 'assets/icons/remove-dependency.svg';

interface RemoveDependencyConfirmationProps {
  taskId: string;
}

const RemoveDependencyConfirmation: FC<RemoveDependencyConfirmationProps> = ({
  taskId,
}) => {
  const dispatch = useDispatch();
  const dependencyToRemove = useSelector(selectDependencyToRemove);
  const taskDisplayId = useSelector(selectTaskDisplayId);
  const taskName = useSelector(selectTaskName);
  const { Modal, modalProps, closeModal, openModal } = useModal();

  useEffect(() => {
    if (dependencyToRemove && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [openModal, modalProps, dependencyToRemove, taskId]);

  const handleCloseModal = useCallback(() => {
    dispatch(setDependencyToRemove(undefined));
    closeModal();
  }, [closeModal, dispatch]);

  const handleRemoveDependency = useCallback(async () => {
    await dispatch(
      removeTaskDependencies({
        taskId,
        dependencies: [dependencyToRemove!.id],
        relationshipType: dependencyToRemove!.relationshipType,
      })
    );
    handleCloseModal();
  }, [dispatch, dependencyToRemove, taskId, handleCloseModal]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('REMOVE'),
        color: 'danger',
        onClick: handleRemoveDependency,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
      },
    ],
    [handleCloseModal, handleRemoveDependency]
  );

  return (
    <Modal
      {...modalProps}
      title={intl.get(
        'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY_CONFIRMATION.TITLE'
      )}
      aria-label={intl.get(
        'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY'
      )}
      titleIcon={{
        src: RemoveDependencyIcon,
      }}
      size='medium'
      actionButtons={actionButtons}
      closeModal={handleCloseModal}
    >
      <Typography variant='body'>
        {intl.getHTML(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY_CONFIRMATION.BODY',
          {
            currenTask: `${taskDisplayId} - ${taskName}`,
            taskToRemove: `${dependencyToRemove?.displayId} - ${dependencyToRemove?.name}`,
          }
        )}
      </Typography>
    </Modal>
  );
};

export default RemoveDependencyConfirmation;
