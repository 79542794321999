import { FormItem, Dropdown } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { timePeriodOptions } from 'utils/constants/strategyGoals';

const TimePeriod = () => {
  return (
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD')}
      labelProps={{ required: true }}
    >
      <Dropdown
        options={timePeriodOptions}
        onChange={() => {}}
        triggerProps={{
          placeholder: intl.get(
            'STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD_PLACEHOLDER'
          ),
          'data-testid': 'time_period_dropdown',
        }}
        listProps={{
          'data-cy': 'time_period_list',
        }}
      />
    </FormItem>
  );
};

export default TimePeriod;
