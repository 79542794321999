import intl from 'react-intl-universal';
import {
  Modal,
  FormItem,
  Dropdown,
  TextField,
} from '@getsynapse/design-system';
import TimePeriod from './components/TimePeriod';
import addGoalIcon from 'assets/icons/add-goal.svg';
import GoalOwner from './components/GoalOwner';

type AddGoalModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const AddGoalModal = ({ isOpen, closeModal }: AddGoalModalProps) => (
  <Modal
    title={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TITLE')}
    titleIcon={{ src: addGoalIcon }}
    aria-label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TITLE')}
    isOpen={isOpen}
    size='medium'
    childrenClassName='max-h-90'
    actionButtons={[
      {
        children: intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.ADD_BUTTON'),
        disabled: true,
        'data-testid': 'add_goal_button',
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: closeModal,
        'data-testid': 'cancel_button',
      },
    ]}
    data-testid='add-goal_modal'
    closeModal={closeModal}
  >
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE')}
      labelProps={{ required: true }}
      className='mb-4'
    >
      <TextField
        onChange={() => {}}
        data-testid='add_goal_title_input'
        placeholder={intl.get(
          'STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE_PLACEHOLDER'
        )}
      />
    </FormItem>
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM')}
      labelProps={{ required: true }}
      className='mb-4'
    >
      <Dropdown
        options={[]}
        onChange={() => {}}
        triggerProps={{
          placeholder: intl.get(
            'STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM_PLACEHOLDER'
          ),
          'data-testid': 'accountable_team_dropdown',
        }}
      />
    </FormItem>
    <GoalOwner />
    <TimePeriod />
  </Modal>
);

export default AddGoalModal;
