import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import addObjectives from 'assets/images/add-objectives.svg';

const CategoryEmptyState = ({
  setIsOpen,
  caption,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caption: string;
}) => {
  return (
    <RenderNoRecords
      dataCy='category-empty-state'
      imageSrc={addObjectives}
      imageClassName='-ml-1'
      className='h-empty-table-body'
      caption={caption}
      labelClassName='mt-0 py-4'
    >
      <div className='mx-auto'>
        <Button
          data-testid='category__add-objectives__empty-state'
          onClick={() => setIsOpen(true)}
        >
          {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ADD_OBJECTIVE')}
        </Button>
      </div>
    </RenderNoRecords>
  );
};

export default CategoryEmptyState;
