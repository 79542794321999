import { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FlatfileSettings, FlatfileResults } from '@flatfile/react';
import intl from 'react-intl-universal';
import { CurrentUser } from 'utils/customTypes';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import { getImportSettings, serializeFlatfileResults } from './helpers';
import {
  getCurrentProjectId,
  getIsProjectClosed,
  getIsProjectArchived,
} from 'state/Project/projectSlice';
import { selectUser } from 'state/User/userSlice';
import {
  createProjectTask,
  setShouldRefreshTasks,
  setSnackBarNotification,
} from 'state/ProjectTasksList/actions/actionsSlice';
import {
  searchUsers,
  selectSearchAssigneesResult,
} from 'state/TasksList/Search/searchSlice';
import {
  fetchTaskStatuses,
  fetchTaskTypes,
  selectTaskStatuses,
  selectTaskTypes,
} from 'state/TasksList/allTasks/allTasksSlice';
import { PROJECT_USER_ACTIONS } from 'utils/constants';

interface ImportProjectTasksResponse {
  currentUser: CurrentUser;
  settings: FlatfileSettings | null;
  onData: (result: FlatfileResults) => Promise<void>;
  disabled: boolean;
}
const useImportProjectTasks: () => ImportProjectTasksResponse = () => {
  const dispatch = useDispatch();
  const mounted = useRef<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const currentUser = useSelector(selectUser);
  const assignees = useSelector(selectSearchAssigneesResult);
  const taskTypes = useSelector(selectTaskTypes);
  const taskStatuses = useSelector(selectTaskStatuses);
  const projectId = useSelector(getCurrentProjectId);
  const isProjectClosed = useSelector(getIsProjectClosed);
  const isProjectArchived = useSelector(getIsProjectArchived);
  const { canUser } = useUserPermissionsContext();

  const init = useCallback(async () => {
    await Promise.all([
      dispatch(
        searchUsers({
          limit: 10000,
        })
      ),
      dispatch(fetchTaskStatuses()),
      dispatch(fetchTaskTypes()),
    ]);
    if (mounted.current) {
      setIsFetching(false);
    }
  }, [dispatch]);

  useEffect(() => {
    mounted.current = true;
    init();
    return () => {
      mounted.current = false;
    };
  }, [init]);

  const settings = useMemo(() => {
    if (isFetching) {
      return null;
    }
    return getImportSettings(Object.values(assignees), taskTypes, taskStatuses);
  }, [assignees, taskTypes, taskStatuses, isFetching]);

  const onData = useCallback(
    async (result: FlatfileResults) => {
      const newTasks = serializeFlatfileResults(projectId, result);
      await dispatch(
        createProjectTask({
          newTasks,
          userId: currentUser.id!,
          sendNotifications: false,
        })
      );
      dispatch(setShouldRefreshTasks(true));
      dispatch(
        setSnackBarNotification({
          notificationMessage: intl.get('TASKS.IMPORT_SUCCESS'),
        })
      );
    },
    [currentUser, projectId, dispatch]
  );

  const isProjectReadOnly = useMemo(
    () =>
      !canUser(PROJECT_USER_ACTIONS.UPDATE_PROJECT) ||
      isProjectClosed ||
      isProjectArchived,
    [isProjectClosed, isProjectArchived, canUser]
  );

  return useMemo(
    () => ({
      currentUser,
      settings,
      onData,
      disabled: isProjectReadOnly,
    }),
    [currentUser, settings, onData, isProjectReadOnly]
  );
};

export default useImportProjectTasks;
