import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import {
  Table,
  Typography,
  Button,
  Icon,
  Avatar,
} from '@getsynapse/design-system';
import { DATE, PATHS } from 'utils/constants';
import { ProjectProcess } from 'utils/customTypes';
import { formattedProjectProcesses } from 'state/Processes/processesSlice';
import DisabledIcon from 'assets/icons/disable-icon.svg';
import TableAvatar from 'Molecules/TableAvatar';
import cognotaAvatar from 'assets/images/cognota-avatar.svg';
import ProcessModal from './ProcessModal';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';

const ProcessesTable = () => {
  const history = useHistory();
  const processes = useSelector(formattedProjectProcesses);
  const [selectedProcess, setSelectedProcess] = useState<ProjectProcess | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditProcess = (process: ProjectProcess) => {
    history.push(`${PATHS.PROCESS_PAGE}/${process.id}`);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (selectedProcess !== null) {
      setSelectedProcess(null);
    }
  };

  return (
    <div className='pt-4 px-4'>
      <ProcessModal
        isModalOpen={isModalOpen}
        onCloseModalHandle={handleCloseModal}
      />
      <div className='flex justify-between items-baseline'>
        <Typography variant='h5' className='py-4'>
          {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.PROCESSES')}
        </Typography>
        <Button
          onClick={() => setIsModalOpen(true)}
          data-cy='projects-settings__add-process-button'
        >
          {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ADD_PROCESS')}
        </Button>
      </div>

      <Table
        className='w-full mb-5'
        canSelectRows={false}
        data-cy='projects-settings__processes-table'
        bodyProps={{
          className: classnames('max-h-table-body overflow-y-auto', {
            block: processes.length > 0,
          }),
        }}
        data={{
          headData: {
            className: 'w-full block h-auto min-h-10',
            rowProps: {
              className: 'table w-full table-fixed min-h-10',
            },
            headCells: [
              {
                content: '',
                className: 'w-11',
              },
              {
                content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.PROCESS_NAME'),
                className: 'font-semibold w-37-percent',
              },
              {
                content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ESTIMATE_TIME'),
                className: 'font-semibold w-58 text-right',
              },
              {
                content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.CREATED_BY'),
                className: 'font-semibold',
              },
              {
                content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.CREATION_DATE'),
                className: 'font-semibold w-47',
              },
            ],
          },
          rows: processes.map((process: ProjectProcess) => {
            const isDefaultProcess = get(
              process,
              'data.createdBySystem',
              false
            );
            const avatarName = get(process, 'data.createdBy.name');
            return {
              id: process.id,
              'data-cy': `projects-settings__processes-table__process-${process.id}`,
              className: 'cursor-pointer table w-full table-fixed',
              onClick: () => handleEditProcess(process),
              cells: [
                {
                  content:
                    process.enabled !== null ? (
                      !process.enabled ? (
                        <div className='w-full h-full flex justify-center items-center'>
                          <Icon
                            src={DisabledIcon}
                            className='text-2xl text-neutral'
                          />
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    ),
                  className: 'w-11',
                },
                {
                  content: (
                    <Link
                      to={`${PATHS.PROCESS_PAGE}/${process.id}`}
                      title={process.processName}
                    >
                      {process.processName}
                    </Link>
                  ),

                  className: 'w-37-percent truncate',
                },
                {
                  content: process.estimatedCompletionTime
                    ? intl.get(
                        'SETTINGS_PAGE.PROJECTS_PAGE.ESTIMATED_COMPLETION_TIME',
                        { time: process.estimatedCompletionTime }
                      )
                    : intl.get(
                        'SETTINGS_PAGE.PROJECTS_PAGE.NO_ESTIMATED_COMPLETION_TIME'
                      ),
                  className: 'text-right w-58',
                },
                {
                  content: isDefaultProcess ? (
                    <div className='flex items-start'>
                      <Avatar
                        iconSrc={cognotaAvatar}
                        iconProps={{ className: 'text-lg' }}
                        bgColorClass='bg-secondary'
                      />
                      <div className='pl-2'>
                        <Typography
                          variant='body'
                          className='text-primary font-semibold'
                        >
                          {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.SYSTEM')}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <TableAvatar
                      user={{
                        data: {
                          firstName: avatarName ? avatarName.split(' ')[0] : '',
                          lastName: avatarName ? avatarName.split(' ')[1] : '',
                          email: '',
                        },
                      }}
                    />
                  ),
                },
                {
                  content: process.createdAt
                    ? moment(process.createdAt).format(
                        DATE.MONTH_DATE_YEAR_FORMAT
                      )
                    : '-',
                  className: 'w-47',
                },
              ],
            };
          }),
        }}
        emptyComponent={
          <RenderNoRecords
            dataCy='no-task-found'
            caption={intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ADD_PROCESS')}
          >
            <div className='mx-auto'>
              <Button
                onClick={() => setIsModalOpen(true)}
                data-cy='projects-settings__add-process-button'
              >
                {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.GET_STARTED')}
              </Button>
            </div>
          </RenderNoRecords>
        }
      />
    </div>
  );
};

export default ProcessesTable;
