import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  Dropdown,
  FormItem,
  Icon,
  Tag,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Option } from 'utils/customTypes';
import { MultipleOptionLI } from 'Pages/NewProjectPage/helpers/snippets';

interface TasksPickerProps {
  tasksOptions: Option[];
  selectedTasks: Option[];
  onSelectTasks: (tasks: Option[]) => void;
  onFilterChange?: (name: string) => void;
}

const TasksPicker: FC<TasksPickerProps> = ({
  tasksOptions,
  selectedTasks,
  onSelectTasks,
  onFilterChange,
}) => {
  return (
    <FormItem
      label={intl.get('PROJECT_DETAIL.FILES_TAB.LINK_TO_TASKS')}
      className='mt-7'
    >
      <Dropdown
        placeholder={intl.get('PROJECT_DETAIL.FILES_TAB.SELECTE_TASKS')}
        onChange={onSelectTasks}
        options={tasksOptions}
        values={selectedTasks}
        multiple
        filterable
        onFilterChange={onFilterChange}
        renderSelectedOptionTag={({ textClassName, ...restOfProps }) => (
          <Tag
            textClassName={`${textClassName} font-semibold`}
            {...restOfProps}
            leadingIcon={{
              name: 'checkmark-circle-outline',
            }}
          />
        )}
        renderTriggerIcon={() => (
          <Icon name='add-circle-outline' className='text-xl' />
        )}
        triggerProps={{
          'data-cy': 'project-content-tasks-dropdown',
          'aria-label': 'project-content-tasks-dropdown',
        }}
        renderOption={(
          option: Option,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => (
          <li
            {...otherProps}
            className={tailwindOverride('group', className, {
              'hover:bg-primary focus-visible:bg-primary': isSelected,
            })}
          >
            <MultipleOptionLI
              label={option.label}
              isSelected={isSelected}
              selectOption={selectOption}
            />
          </li>
        )}
      />
    </FormItem>
  );
};

export default TasksPicker;
