import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import {
  fetchAvailableTaskAssignees,
  FetchAvailableTaskAssigneesParams,
} from 'api/taskAvailableAssignees';
import {
  FetchTaskDetailParams,
  fetchTask,
  updateTask,
  UpdateTaskDetailParams,
} from 'api/taskDetail';
import axios from 'axios';
import { RootState } from 'state/store';
import { normalizeState } from 'state/utils/normalizeState';
import {
  TaskDetailState,
  TaskState,
  TaskNotFoundError,
} from 'types/store/taskDetail';
import { TaskAssignee } from 'types/store/tasks';
import { SLICE_STATUS, TASK_STATUS } from 'utils/constants';

export const initialState: TaskDetailState = {
  status: SLICE_STATUS.IDLE,
  permissions: {
    canDelete: false,
    canEdit: false,
  },
  availableAssigneesStatus: SLICE_STATUS.IDLE,
  availableAssigneeIds: [],
  availableAssignees: {},
  taskActualHoursLoading: false,
};

/* =============================== ACTIONS ================================ */
export const createActualHours = createAction<number>(
  'tasksDetail/createActualHours'
);
export const removeActualHours = createAction<number>(
  'tasksDetail/removeActualHours'
);

/* =============================== SELECTORS ================================ */
export const selectTaskSliceStatus = (state: RootState) =>
  state.taskDetail.status;
export const selectTaskDetail = (state: RootState) =>
  state.taskDetail.currentTask;
const selectTaskId = (state: RootState) =>
  state.taskDetail.currentTask?.id || '';
export const selectTaskName = (state: RootState) => {
  return state.taskDetail.currentTask?.name || '';
};
const selectTaskDescription = (state: RootState) =>
  state.taskDetail.currentTask?.description || '';
export const selectCanDelete = (state: RootState) =>
  state.taskDetail.permissions?.canDelete || false;
export const selectCanEdit = (state: RootState) =>
  state.taskDetail.permissions?.canEdit || false;

export const selectAssignees = (state: RootState) =>
  state.taskDetail.currentTask?.assignees || [];

export const selectTaskType = (state: RootState) =>
  state.taskDetail.currentTask?.type || '';

export const selectTaskProjectId = (state: RootState) =>
  state.taskDetail.currentTask?.project?.id || '';

export const selectTaskCompletionDate = (state: RootState) => {
  return state.taskDetail.currentTask?.completedDate || '';
};

export const selectTaskEstimateHours = (state: RootState) =>
  state.taskDetail.currentTask?.estimateHours || '';
export const selectTaskActualHoursSum = (state: RootState) =>
  parseFloat(state.taskDetail.currentTask?.actualHours || '00');

export const selectTaskDisplayId = createSelector(
  [selectTaskDetail],
  (taskDetail: TaskState | undefined) => taskDetail?.displayId || ''
);

export const selectAvailableAssignees = (state: RootState) => {
  return state.taskDetail.availableAssigneeIds.map((id) => {
    return state.taskDetail.availableAssignees[id];
  });
};

export const selectActualHourEntries = (state: RootState) => {
  return Object.values(state.taskDetail.taskActualHours || {});
};

export const selectIsTaskDisabled = createSelector(
  [selectTaskDetail],
  (taskDetail: TaskState | undefined) => taskDetail?.disabled || false
);

export const selectTaskProject = createSelector(
  [selectTaskDetail],
  (taskDetail: TaskState | undefined) =>
    taskDetail?.project || ({} as TaskState['project'])
);

export const selectTaskStartDate = createSelector(
  [selectTaskDetail],
  (taskDetail: TaskState | undefined) => taskDetail?.startDate || ''
);

export const selectTaskDueDate = createSelector(
  [selectTaskDetail],
  (taskDetail: TaskState | undefined) => taskDetail?.endDate || ''
);

export const selectTaskStatus = createSelector(
  [selectTaskDetail],
  (taskDetail: TaskState | undefined) => taskDetail?.status || TASK_STATUS.NEW
);

export const selectTaskInformation = createSelector(
  [
    selectTaskId,
    selectTaskName,
    selectTaskDescription,
    selectTaskStatus,
    selectTaskType,
    selectTaskEstimateHours,
    selectIsTaskDisabled,
    selectTaskCompletionDate,
    selectAssignees,
    selectTaskStartDate,
    selectTaskDueDate,
  ],
  (
    id,
    name,
    description,
    status,
    type,
    estimateHours,
    disabled,
    completedDate,
    assignees,
    startDate,
    endDate
  ) => ({
    id,
    name,
    description,
    status,
    type,
    estimateHours,
    disabled,
    completedDate,
    assignees,
    startDate,
    endDate,
  })
);

/* =============================== THUNK ================================ */

export const fetchTaskDetail = createAsyncThunk(
  'tasksDetail/fetchTask',
  async (params: FetchTaskDetailParams, { rejectWithValue }) => {
    try {
      const response = await fetchTask(params);
      return {
        taskDetail: response.task,
        permissions: response.permissions,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.response?.data.error,
          code: error.response?.status,
        });
      }
      return rejectWithValue({
        message: 'Error fetching team tasks',
      });
    }
  }
);

export const updateTaskDetail = createAsyncThunk(
  'tasksDetail/updateTaskDetail',
  async (params: UpdateTaskDetailParams, { rejectWithValue }) => {
    try {
      const response = await updateTask(params);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error updating task');
    }
  }
);

export const fetchAvailableAssignees = createAsyncThunk(
  'tasksDetail/fetchAvailableAssignees',
  async (
    params: FetchAvailableTaskAssigneesParams,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      if (state.taskDetail.availableAssigneesStatus === SLICE_STATUS.UPDATING) {
        return;
      }

      const response = await fetchAvailableTaskAssignees(params);

      return response.availableAssignees;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetState: () => initialState,
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskDetail.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTaskDetail.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.currentTask = action.payload.taskDetail as TaskState;
        if (action.payload.permissions) {
          state.permissions.canEdit =
            action.payload.permissions.canEdit ?? false;
          state.permissions.canDelete =
            action.payload.permissions.canDelete ?? false;
        }
      })
      .addCase(fetchTaskDetail.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
        const payload = action.payload as { message: string; code: number };
        if (payload.code === 404) {
          throw new TaskNotFoundError(payload.message);
        }
        throw new Error(payload.message);
      })
      .addCase(fetchAvailableAssignees.pending, (state) => {
        state.availableAssigneesStatus = SLICE_STATUS.LOADING;
      })
      .addCase(fetchAvailableAssignees.fulfilled, (state, action) => {
        state.availableAssigneesStatus = SLICE_STATUS.IDLE;
        if (action.payload) {
          const normalizedState = normalizeState<TaskAssignee>(action.payload);

          state.availableAssigneeIds = normalizedState.ids;
          state.availableAssignees = normalizedState.entities;
        }
      })
      .addCase(updateTaskDetail.pending, (state) => {
        state.status = SLICE_STATUS.UPDATING;
      })
      .addCase(updateTaskDetail.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.currentTask = {
          ...state.currentTask,
          ...(action.payload as TaskState),
        };
      })
      .addCase(updateTaskDetail.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error('Error updating task');
      })
      .addCase(createActualHours, (state, action) => {
        const actualHours = state.currentTask?.actualHours ?? '0';
        const newActualHours = parseFloat(actualHours) + action.payload;
        state.currentTask = {
          ...state.currentTask,
          actualHours: newActualHours.toString(),
        } as TaskState;
      })
      .addCase(removeActualHours, (state, action) => {
        const actualHours = state.currentTask?.actualHours ?? '0';
        const newActualHours = parseFloat(actualHours) - action.payload;
        state.currentTask = {
          ...state.currentTask,
          actualHours: newActualHours.toString(),
        } as TaskState;
      });
  },
});

export const { resetState, setStatus } = tasksSlice.actions;

export default tasksSlice.reducer;
