import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addTaskDependencies } from 'state/TaskDependencies/TaskDependenciesSlice';
import { RelationshipType } from 'types/store/taskDependencies';

interface NewTaskDependencies {
  relationshipType: RelationshipType;
  dependencies: string[];
}

export interface UseAddTaskDependencies {
  taskId: string;
  onFail?: () => void;
}

const useAddTaskDependencies = ({ taskId, onFail }: UseAddTaskDependencies) => {
  const dispatch = useDispatch();
  const [newTaskDependencies, setNewTaskDependencies] =
    useState<NewTaskDependencies>({
      relationshipType: RelationshipType.DependsOn,
      dependencies: [],
    });

  const updateRelationshipType = useCallback(
    (relationshipType: RelationshipType) => {
      setNewTaskDependencies((prev) => ({
        ...prev,
        relationshipType,
      }));
    },
    []
  );

  const updateDependencies = useCallback((dependencies: string[]) => {
    setNewTaskDependencies((prev) => ({
      ...prev,
      dependencies,
    }));
  }, []);

  const canSubmit =
    newTaskDependencies.relationshipType &&
    newTaskDependencies.dependencies.length > 0;

  const submitTaskDependencies = useCallback(async () => {
    try {
      await dispatch(
        addTaskDependencies({
          taskId,
          relationshipType: newTaskDependencies.relationshipType,
          dependencies: newTaskDependencies.dependencies,
        })
      );
    } catch (error) {
      onFail && onFail();
    }
  }, [dispatch, newTaskDependencies, taskId, onFail]);

  return {
    canSubmit,
    updateRelationshipType,
    updateDependencies,
    submitTaskDependencies,
  };
};

export default useAddTaskDependencies;
