import { PATHS } from './constants';
import { Task } from './customTypes';

export const getTaskRoute = (task: Task) => {
  return `/project/${task.project_id}/tasks/${task.id}`;
};

export const getRequestRoute = (
  requestId: string,
  organizationId: string
): string => `${PATHS.REQUEST_PAGE}/${requestId}?accountId=${organizationId}`;
