import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getRequests,
  requestsSelectors,
} from 'state/normalized/requests/requestsSlice';
import { useAppThunkDispatch } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { Pagination } from 'utils/customTypes';

import { Request } from 'types/store/normalized';
import { RequestStatus } from 'utils/types/request';

interface UseRequestsTableResponse {
  requests: Request[];
  pageNumber: number;
  loading: boolean;
  onPageChange: (pagination: Pagination) => void;
}
const useRequestsTable = (
  statuses: readonly RequestStatus[]
): UseRequestsTableResponse => {
  const dispatch = useAppThunkDispatch();
  const requests = useSelector(requestsSelectors.selectAll);
  const status = useSelector(requestsSelectors.selectStatus);
  const pageNumber = useSelector(requestsSelectors.selectPageNumber);

  const loading: boolean =
    status === SLICE_STATUS.LOADING && requests.length === 0;

  useEffect(() => {
    dispatch(getRequests({ status: statuses }));
  }, [dispatch, statuses]);

  const onPageChange = ({ limit, offset }: Pagination) => {
    dispatch(getRequests({ pageSize: limit, offset, status: statuses }));
  };

  return {
    requests,
    pageNumber,
    loading,
    onPageChange,
  };
};

export default useRequestsTable;
