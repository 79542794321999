import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  LinkedTask,
  LinkedTaskPermissions,
  LinkedTaskToRemove,
  LinkedTaskWithRelationshipType,
  FailedDependency,
  RelationshipType,
  FailedDependencyError,
} from 'types/store/taskDependencies';
import { TaskStatus } from 'types/store/tasks';
import {
  fetchPredecessors,
  fetchSuccessors,
  createTaskDependencies,
  CreateTaskDependenciesParams,
  DeleteTaskDependenciesParams,
  deleteTaskDependencies,
} from 'api/taskDependencies';
import { RootState } from 'state/store';
import {
  selectTaskDueDate,
  selectTaskStartDate,
} from 'state/TaskDetail/taskSlice';
import {
  validateIfLinkedTaskDueDateIsPast,
  validateIfLinkedTaskDatesOverlap,
} from './helpers';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';

const successorsDependenciesAdapter = createEntityAdapter<LinkedTask>({
  selectId: (taskDependency) => taskDependency.id,
});

const predecessorsDependenciesAdapter = createEntityAdapter<LinkedTask>({
  selectId: (taskDependency) => taskDependency.id,
});

interface DependenciesState {
  status?: Status;
  permissions?: {
    [taskId: string]: LinkedTaskPermissions;
  };
}

interface TaskDependenciesState {
  successors: ReturnType<
    typeof successorsDependenciesAdapter.getInitialState<DependenciesState>
  >;
  predecessors: ReturnType<
    typeof predecessorsDependenciesAdapter.getInitialState<DependenciesState>
  >;
  shouldRefresh?: boolean;
  addTaskDependenciesStatus?: Status;
  removeTaskDependenciesStatus?: Status;
  createdDependencies?: LinkedTaskWithRelationshipType[];
  failedDependencies?: FailedDependency[];
  dependencyToRemove?: LinkedTaskToRemove;
}

const initialState: TaskDependenciesState = {
  successors: successorsDependenciesAdapter.getInitialState<DependenciesState>(
    {}
  ),
  predecessors:
    predecessorsDependenciesAdapter.getInitialState<DependenciesState>({}),
};

export const fetchSuccessorsDependencies = createAsyncThunk(
  'taskDependencies/fetchSuccessorsDependencies',
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await fetchSuccessors(taskId);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchPredecessorsDependencies = createAsyncThunk(
  'taskDependencies/fetchPredecessorsDependencies',
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await fetchPredecessors(taskId);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error as Error);
    }
  }
);

export const addTaskDependencies = createAsyncThunk(
  'taskDependencies/addTaskDependencies',
  async (
    params: CreateTaskDependenciesParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createTaskDependencies(params);
      return { ...response, relationshipType: params.relationshipType };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const failures = error.response?.data.failures;
        dispatch(setAddDependenciesStatus(SLICE_STATUS.FAILED));
        dispatch(setFailedDependencies(failures));
      }
      return rejectWithValue('Failed to create dependencies');
    }
  }
);

export const removeTaskDependencies = createAsyncThunk(
  'taskDependencies/removeTaskDependencies',
  async (
    params: DeleteTaskDependenciesParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await deleteTaskDependencies(params);
      return {
        ...response,
        dependencies: params.dependencies,
        relationshipType: params.relationshipType,
      };
    } catch (error) {
      return rejectWithValue('Failed to remove dependencies');
    }
  }
);

const TaskDependenciesSlice = createSlice({
  name: 'taskDependencies',
  initialState: {
    ...initialState,
  },
  reducers: {
    setShouldRefresh: (state, action) => {
      state.shouldRefresh = action.payload;
    },
    setAddDependenciesStatus: (state, action) => {
      state.addTaskDependenciesStatus = action.payload;
    },
    setRemoveDependenciesStatus: (state, action) => {
      state.removeTaskDependenciesStatus = action.payload;
    },
    setFailedDependencies: (state, action) => {
      state.failedDependencies = action.payload;
    },
    setCreatedDependencies: (state, action) => {
      state.createdDependencies = action.payload;
    },
    setDependencyToRemove: (state, action) => {
      state.dependencyToRemove = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuccessorsDependencies.pending, (state) => {
        state.successors.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchSuccessorsDependencies.fulfilled, (state, action) => {
        successorsDependenciesAdapter.setAll(
          state.successors,
          action.payload.successors
        );
        state.successors.permissions = action.payload.permissions;
        state.successors.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchSuccessorsDependencies.rejected, (state) => {
        state.successors.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchPredecessorsDependencies.pending, (state) => {
        state.predecessors.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchPredecessorsDependencies.fulfilled, (state, action) => {
        predecessorsDependenciesAdapter.setAll(
          state.predecessors,
          action.payload.predecessors
        );
        state.predecessors.permissions = action.payload.permissions;
        state.predecessors.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchPredecessorsDependencies.rejected, (state) => {
        state.predecessors.status = SLICE_STATUS.FAILED;
      })
      .addCase(addTaskDependencies.fulfilled, (state, action) => {
        state.addTaskDependenciesStatus = SLICE_STATUS.SUCCESS;
        state.shouldRefresh = true;
        state.createdDependencies = action.payload?.successes.map(
          (linkedTask: LinkedTask) => ({
            ...linkedTask,
            relationshipType: action.payload.relationshipType,
          })
        );
        state.failedDependencies = action.payload?.failures ?? undefined;
      })
      .addCase(addTaskDependencies.rejected, (state) => {
        state.addTaskDependenciesStatus = SLICE_STATUS.FAILED;
        throw new Error('Failed to create dependencies');
      })
      .addCase(removeTaskDependencies.fulfilled, (state, action) => {
        state.removeTaskDependenciesStatus = SLICE_STATUS.SUCCESS;
        if (action.payload.relationshipType === RelationshipType.DependsOn) {
          predecessorsDependenciesAdapter.removeMany(
            state.predecessors,
            action.payload.dependencies
          );
        } else {
          successorsDependenciesAdapter.removeMany(
            state.successors,
            action.payload.dependencies
          );
        }
      })
      .addCase(removeTaskDependencies.rejected, (state) => {
        state.removeTaskDependenciesStatus = SLICE_STATUS.FAILED;
      });
  },
});

const selectFetchPredecessorsStatus = (state: RootState) =>
  state.taskDependencies.predecessors.status;

const selectFetchSuccessorsStatus = (state: RootState) =>
  state.taskDependencies.successors.status;

export const {
  selectAll: selectTaskSuccessorsDependencies,
  selectById: selectTaskSuccessorDependencyById,
} = successorsDependenciesAdapter.getSelectors(
  (state: RootState) => state.taskDependencies.successors
);

export const {
  selectAll: selectTaskPredecessorsDependencies,
  selectById: selectTaskPredecessorDependencyById,
} = predecessorsDependenciesAdapter.getSelectors(
  (state: RootState) => state.taskDependencies.predecessors
);

export const selectIsFetchingDependencies = createSelector(
  [selectFetchPredecessorsStatus, selectFetchSuccessorsStatus],
  (predecessorsStatus, successorsStatus) =>
    predecessorsStatus === SLICE_STATUS.LOADING ||
    successorsStatus === SLICE_STATUS.LOADING
);

export const selectTaskPredecessorsWithPermissions = createSelector(
  [
    selectTaskPredecessorsDependencies,
    (state: RootState) => state.taskDependencies.predecessors.permissions,
    selectTaskStartDate,
  ],
  (predecessors, permissions, currentTaskStartDate) =>
    predecessors.map((predecessor) => ({
      ...predecessor,
      permissions: permissions ? permissions[predecessor.id] : undefined,
      isTaskOverDue: validateIfLinkedTaskDueDateIsPast(
        predecessor.dueDate,
        predecessor.status === TaskStatus.Completed
      ),
      overlapingDates: validateIfLinkedTaskDatesOverlap(
        predecessor.dueDate,
        currentTaskStartDate
      ),
    }))
);

export const selectTaskSuccessorsWithPermissions = createSelector(
  [
    selectTaskSuccessorsDependencies,
    (state: RootState) => state.taskDependencies.successors.permissions,
    selectTaskDueDate,
  ],
  (successors, permissions, currentTaskDueDate) =>
    successors.map((successor) => ({
      ...successor,
      permissions: permissions ? permissions[successor.id] : undefined,
      isTaskOverDue: validateIfLinkedTaskDueDateIsPast(
        successor.dueDate,
        successor.status === TaskStatus.Completed
      ),
      overlapingDates: validateIfLinkedTaskDatesOverlap(
        currentTaskDueDate,
        successor.startDate
      ),
    }))
);

export const selectHasTaskDependencies = createSelector(
  [selectTaskPredecessorsDependencies, selectTaskSuccessorsDependencies],
  (predecessors, successors) => predecessors.length > 0 || successors.length > 0
);

export const selectShouldRefresh = (state: RootState) =>
  state.taskDependencies.shouldRefresh;

export const selectAddDependenciesStatus = (state: RootState) =>
  state.taskDependencies.addTaskDependenciesStatus;

export const selectRemoveDependenciesStatus = (state: RootState) =>
  state.taskDependencies.removeTaskDependenciesStatus;

export const selectFailedDependencies = (state: RootState) =>
  state.taskDependencies.failedDependencies || [];

export const selectConflictingDependencies = createSelector(
  [selectFailedDependencies],
  (failedDependencies) =>
    failedDependencies.filter(
      (dependency) =>
        dependency.error === FailedDependencyError.CircularDependency
    )
);

export const selectFailedDependenciesWithoutConflicts = createSelector(
  [selectFailedDependencies],
  (failedDependencies) =>
    failedDependencies.filter(
      (dependency) =>
        dependency.error !== FailedDependencyError.CircularDependency
    )
);

export const selectCreatedDependencies = (state: RootState) =>
  state.taskDependencies.createdDependencies;

export const selectNumberOfCreatedDependencies = createSelector(
  [selectCreatedDependencies],
  (createdDependencies) => createdDependencies?.length
);

export const selectCreatedDependenciesWithOverlappingDates = createSelector(
  [selectCreatedDependencies, selectTaskStartDate, selectTaskDueDate],
  (createdDependencies, currentTaskStartDate, currentTaskDueDate) => {
    return createdDependencies?.filter(
      (dependency: LinkedTaskWithRelationshipType) => {
        if (dependency.relationshipType === RelationshipType.Blocks) {
          return validateIfLinkedTaskDatesOverlap(
            currentTaskDueDate,
            dependency.startDate
          );
        } else {
          return validateIfLinkedTaskDatesOverlap(
            dependency.dueDate,
            currentTaskStartDate
          );
        }
      }
    );
  }
);

export const selectDependencyToRemove = createSelector(
  [(state: RootState) => state.taskDependencies.dependencyToRemove],
  (dependencyToRemove) => dependencyToRemove
);

export const {
  setFailedDependencies,
  setCreatedDependencies,
  setShouldRefresh,
  setAddDependenciesStatus,
  setDependencyToRemove,
  setRemoveDependenciesStatus,
} = TaskDependenciesSlice.actions;

export default TaskDependenciesSlice.reducer;
