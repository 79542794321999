import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Table,
  TableOperationHeader,
  Typography,
  OverflowMenu,
  OverflowMenuItem,
  TableFilter,
  tailwindOverride,
  Tag,
} from '@getsynapse/design-system';
import {
  updateUser,
  exportTeamMembers,
  setLDTeamsFilters,
  setSearchParam,
  selectFilters,
  getLearningTeamMemberToUpdate,
  setLearningTeamMemberIdToUpdate,
  selectLearningTeamMemberIdToUpdate,
  exportTeamsStatus,
} from 'state/Teams/teamsSlice';
import { selectOrganizationCurrency } from 'state/Organization/organizationSlice';
import { Owner, filter, TableExportOptions } from 'utils/customTypes';
import {
  UPDATE_MEMBER_OPTIONS,
  TEAM_MEMBER_COLUMNS,
  COLUMN_OPTION_TYPES,
  TABLE_EXPORT_OPTIONS,
  SLICE_STATUS,
  PATHS,
} from 'utils/constants';
import noTeamsFound from 'assets/images/no-teams-found.svg';
import emptyFilteredTeams from 'assets/images/empty-filtered-teams.svg';
import TableCell from 'Atoms/TableCell';
import TableAvatar from 'Molecules/TableAvatar';
import TableSubHeaderContent from 'Pages/TeamsPage/helpers/TeamSubHeaderContent';
import teamHeader from 'Pages/TeamsPage/helpers/teamHeader';
import EditMemberModal from './EditMemberModal';
import TimeOffModal from './TimeOffModal';
import filterInput from './filterInput';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import ExportButton from 'Organisms/TableHeaderActionButtons/ExportButton';
import type { LearningTeam } from 'utils/types/learningTeam';

type TeamsTableProps = {
  learningTeamsRows: (LearningTeam | Owner)[];
  allTeamsMembers: Owner[];
};

const TeamsTable = ({
  learningTeamsRows,
  allTeamsMembers,
}: TeamsTableProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const learningTeamMemberToUpdate = useSelector(getLearningTeamMemberToUpdate);
  const learningTeamMemberIdToUpdate = useSelector(
    selectLearningTeamMemberIdToUpdate
  );
  const currency = useSelector(selectOrganizationCurrency);
  const filterValues = useSelector(selectFilters);
  const exportStatus = useSelector(exportTeamsStatus);
  const isTableEmpty = learningTeamsRows.length === 0;
  const [editMemberData, setEditMemberData] = useState<{
    user: Owner;
    isOpen?: boolean;
  }>({
    user: {},
    isOpen: false,
  });
  const [data, setData] = useState<{
    searchParam: string;
    user: Owner;
    isOpen?: boolean;
    showFilter: boolean;
  }>({
    searchParam: '',
    user: {},
    isOpen: false,
    showFilter: false,
  });
  const [timeOffData, setTimeOffData] = useState<{
    username: string;
    userId: string;
    isOpen?: boolean;
  }>({
    username: '',
    userId: '',
    isOpen: false,
  });
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const allTeamsMembersIds = useMemo(
    () => allTeamsMembers.map((teamMember: Owner) => teamMember.id!),
    [allTeamsMembers]
  );

  const currentPageTeamsMembersIds = useMemo(
    () =>
      learningTeamsRows
        .filter(
          (learningTeamsRow: LearningTeam | Owner) =>
            !('name' in learningTeamsRow)
        )
        .map((teamMember: Owner) => teamMember.id!),
    [learningTeamsRows]
  );

  const handleUserUpdate = useCallback(
    (id: string, newUser: Owner) => {
      dispatch(updateUser({ id, newUser }));
    },
    [dispatch]
  );

  const handleSetFilters = useCallback(
    (filters: filter[]) => {
      dispatch(setLDTeamsFilters(filters));
    },
    [dispatch]
  );

  useEffect(() => {
    if (learningTeamMemberIdToUpdate && !isEmpty(learningTeamMemberToUpdate)) {
      setEditMemberData((prevData) => ({
        ...prevData,
        user: learningTeamMemberToUpdate,
        isOpen: true,
      }));
    }
  }, [learningTeamMemberToUpdate, learningTeamMemberIdToUpdate]);

  const handleSearch = (value: string) => {
    setData((prevData) => ({ ...prevData, searchParam: value }));
    dispatch(setSearchParam(value));
  };

  const handleMenuSelect = (
    option: typeof UPDATE_MEMBER_OPTIONS[number],
    user: Owner
  ) => {
    switch (option) {
      case UPDATE_MEMBER_OPTIONS[0]:
        setEditMemberData((prevData) => ({ ...prevData, user, isOpen: true }));
        break;
      case UPDATE_MEMBER_OPTIONS[1]:
        history.push(`${PATHS.MANAGE_TIME_OFF}/${user.id}`);
        break;
      default:
        break;
    }
    if (learningTeamMemberIdToUpdate) {
      dispatch(setLearningTeamMemberIdToUpdate(null));
    }
  };

  const columnOptions: {
    name: string;
    value: string;
    operators: {
      value: string;
      name: string;
    }[];
    type: string;
  }[] = useMemo(() => {
    const options = TEAM_MEMBER_COLUMNS.slice(1);
    return options.map(
      (column: { value: string; operators: string[]; labelKey: string }) => {
        return {
          name: intl.get(`TEAMS.TABLE.${column.labelKey}`),
          type: COLUMN_OPTION_TYPES.DATE,
          ...column,
          operators: column.operators.map((operator) => ({
            name: intl.get(`OPERATORS.${operator}`),
            value: operator,
          })),
        };
      }
    );
  }, []);

  const handleRowSelect = (selectedRows: string[]) => {
    setSelectedMembers(selectedRows);
  };

  const onExportHandler = () => {
    dispatch(exportTeamMembers(selectedMembers));
  };

  const handleSelectedExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedMembers(allTeamsMembersIds);
      return;
    }
    if (
      exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE &&
      currentPageTeamsMembersIds.length > 0
    ) {
      setSelectedMembers(currentPageTeamsMembersIds);
      return;
    }

    setSelectedMembers([]);
  };

  const [learningTeams, setLearningTeams] = useState(learningTeamsRows);
  const [collapsedSection, setCollapsedSection] = useState<string[]>([]);

  const handleToggleSection = (sectionId: any) => {
    let updatedSections = [];
    const sectionIndex = collapsedSection.findIndex(
      (value) => value === sectionId
    );
    if (sectionIndex !== -1) {
      updatedSections = [
        ...collapsedSection.slice(0, sectionIndex),
        ...collapsedSection.slice(sectionIndex + 1),
      ];
    } else {
      updatedSections = collapsedSection.concat(sectionId);
    }
    setCollapsedSection(updatedSections);
  };

  useEffect(() => {
    const activeCollapsedRows = learningTeamsRows.filter(
      (team: any) =>
        team.name ||
        !collapsedSection.includes(
          team?.learning_team_members?.learningTeamId ||
            team?.learning_team_managers?.learningTeamId
        )
    );
    setLearningTeams(activeCollapsedRows);
  }, [learningTeamsRows, collapsedSection]);

  return (
    <div className='mt-4'>
      <EditMemberModal
        {...editMemberData}
        setIsOpen={(value) => {
          setEditMemberData((prevData) => ({ ...prevData, isOpen: value }));
          if (!value && learningTeamMemberIdToUpdate) {
            dispatch(setLearningTeamMemberIdToUpdate(null));
          }
        }}
        onUserUpdate={handleUserUpdate}
      />
      <TimeOffModal
        {...timeOffData}
        setIsOpen={(value) =>
          setTimeOffData((prevData) => ({ ...prevData, isOpen: value }))
        }
      />
      <TableOperationHeader
        className='border-0 rounded-b-none bg-neutral-white'
        inputValue={data.searchParam}
        setInputValue={handleSearch}
        filterComponent={
          <div className='bg-neutral-white'>
            <TableFilter
              columnOptions={columnOptions}
              filterVisible={data.showFilter}
              filterAction={handleSetFilters}
              filterInputElement={filterInput}
            />
          </div>
        }
        actionButtons={
          <>
            <FilterButton
              disabled={!data.showFilter}
              aria-pressed={data.showFilter}
              onToggleFilter={() =>
                setData((prevData) => ({
                  ...prevData,
                  showFilter: !prevData.showFilter,
                }))
              }
            />
            <ExportButton
              disabled={isEmpty(selectedMembers)}
              exportTooltipText={intl.get('TEAMS.EXPORT_TOOLTIP')}
              onExport={onExportHandler}
              loading={exportStatus === SLICE_STATUS.LOADING}
            />
          </>
        }
      />
      <div
        className={tailwindOverride('w-full rounded-b', {
          'max-h-request-details': !data.showFilter,
          'max-h-filtered-table': data.showFilter,
          'overflow-auto border border-neutral-lighter-two': !isTableEmpty,
          'overflow-hidden border-r border-neutral-lighter-two': isTableEmpty,
        })}
      >
        <Table
          className={tailwindOverride('w-full', {
            'border-0': !isTableEmpty,
          })}
          onSelectRows={handleRowSelect}
          selectedRowsIds={selectedMembers}
          data={{
            ...teamHeader(handleSelectedExportOption),
            rows: learningTeams.map((element) => {
              if (!get(element, 'name')) {
                return {
                  id: get(element, 'id'),
                  className: 'group',
                  cells: [
                    {
                      content: (
                        <TableCell
                          className={tailwindOverride(
                            'py-1 flex-1 flex',
                            'text-neutral-black text-sm font-semibold',
                            'items-start justify-between'
                          )}
                        >
                          <TableAvatar
                            user={{
                              ...element,
                              data: {
                                firstName: get(element, 'data.firstName'),
                                lastName: get(element, 'data.lastName'),
                                email: get(element, 'data.email'),
                              },
                            }}
                          />
                          {!isEmpty(get(element, 'learning_team_managers')) && (
                            <Tag
                              label={intl.get('TEAMS.TABLE.TEAM_MANAGER')}
                              className='bg-secondary-lighter border border-transparent group-hover:border-secondary-dark mb-4'
                              textClassName='text-secondary-darker'
                            />
                          )}
                        </TableCell>
                      ),
                    },
                    {
                      content: (
                        <Typography className='pb-6 text-neutral-black text-sm flex-1'>
                          {get(element, 'data.jobTitle')}
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <Typography className='pb-6 text-neutral-black text-sm flex-1'>
                          {intl.get(
                            `TEAMS.EMPLOYMENT_TYPE.${get(
                              element,
                              'data.employmentType',
                              'UNDEFINED'
                            )}`
                          )}
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <Typography className='pb-6 text-neutral-black text-sm flex-1'>
                          {intl.get(
                            `COUNTRIES.${get(
                              element,
                              'country_iso_3166_1_alpha_2_code',
                              'UNDEFINED'
                            )}`
                          )}
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <Typography className='pb-6 text-neutral-black text-sm flex-1'>
                          {get(element, 'data.rateHour') &&
                            `${get(element, 'data.rateHour')} ${currency}`}
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <OverflowMenu
                          menuButtonProps={{
                            children: null,
                            'data-testid': 'my-menu-button',
                            className: tailwindOverride(
                              'pb-6',
                              'text-neutral-black text-sm font-semibold'
                            ),
                          }}
                          menuListProps={{
                            className: 'shadow-raised',
                          }}
                        >
                          {UPDATE_MEMBER_OPTIONS.map((option) => (
                            <OverflowMenuItem
                              key={option}
                              onSelect={() => handleMenuSelect(option, element)}
                            >
                              {intl.get(`TEAMS.UPDATE_MENU.${option}`)}
                            </OverflowMenuItem>
                          ))}
                        </OverflowMenu>
                      ),
                    },
                  ],
                  'data-cy': `team-member-${get(element, 'id')}`,
                };
              } else {
                return {
                  canSelectRows: false,
                  cells: [
                    {
                      content: <TableCell className='shadow-none' />,
                    },
                    {
                      colSpan: 6,
                      content: (
                        <TableSubHeaderContent
                          name={get(element, 'name')}
                          teamId={get(element, 'id')}
                          collapsedSection={collapsedSection}
                          onToggleSection={() =>
                            handleToggleSection(get(element, 'id'))
                          }
                        />
                      ),
                    },
                  ],
                };
              }
            }),
            total: allTeamsMembers.length,
          }}
          emptyComponent={
            <>
              {!isEmpty(data.searchParam) && (
                <RenderNoRecords
                  imageSrc={noTeamsFound}
                  caption={intl.get('TASKS.TABLE.NO_RECORDS')}
                  className='h-empty-table-body'
                />
              )}
              {!isEmpty(filterValues) && (
                <RenderNoRecords
                  imageSrc={emptyFilteredTeams}
                  caption={intl.get('TASKS.TABLE.NO_RECORDS')}
                  className='h-empty-filtered-table-body'
                />
              )}
            </>
          }
          data-cy='teams-table'
        />
      </div>
    </div>
  );
};

export default TeamsTable;
