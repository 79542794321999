import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { initAxios } from 'utils/axios';

export default abstract class BaseApi<T> {
  protected readonly axiosInstance: AxiosInstance;

  constructor(url: string) {
    this.axiosInstance = initAxios(url);
  }

  async getById(id: string): Promise<T> {
    const { data } = await this.axiosInstance.get<T>(id);
    return data;
  }

  async getList(config?: AxiosRequestConfig): Promise<T[]> {
    const { data } = await this.axiosInstance.get<T[]>('', config);
    return data;
  }

  async getByIds(ids: string[]): Promise<T[]> {
    const { data } = await this.axiosInstance.get<T[]>('', {
      params: { ids: ids.join(',') },
    });
    return data;
  }
}
