import { FC, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import { Option } from 'utils/customTypes';
import {
  fetchAvailableProjects,
  selectSearchProjects,
  selectSearchProjectResult,
} from 'state/TasksList/Search/searchSlice';
import { isUserOrganizationAdmin as selectIsUserOrganizationAdmin } from 'state/User/userSlice';
import { RootState } from 'state/store';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';

interface ProjectFilterFieldProps {
  selectedPrograms: string[];
  project: string[];
  onChange: (project: string[]) => void;
  isFiltering?: boolean;
}

const ProjectFilterField: FC<ProjectFilterFieldProps> = ({
  project,
  onChange,
  isFiltering = true,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>('');
  const projectsList = useSelector((state: RootState) =>
    selectSearchProjects(state, searchValue.length > 0)
  );
  const projectsMap = useSelector(selectSearchProjectResult);
  const isUserOrganizationAdmin = useSelector(selectIsUserOrganizationAdmin);
  const participatingProjectsOnly = !isUserOrganizationAdmin && !isFiltering;

  const onFilterChange = useRef(
    debounce(
      (name: string) =>
        dispatch(fetchAvailableProjects({ name, participatingProjectsOnly })),
      500
    )
  );

  const handleFilterChange = useCallback(
    (name: string) => {
      setSearchValue(name);
      onFilterChange.current(name);
    },
    [onFilterChange]
  );

  const handleOnChange = useCallback(
    (options: Option[]) => {
      onChange(options.map((option) => option.value));
    },
    [onChange]
  );

  useEffect(() => {
    dispatch(fetchAvailableProjects({ participatingProjectsOnly }));
  }, [dispatch, participatingProjectsOnly]);

  const projectOptions = useMemo(
    () =>
      projectsList.map((project) => {
        return {
          value: project.id,
          label: `${project.displayId} - ${project.name}`,
        };
      }),
    [projectsList]
  );

  const selectedProjectOptions = useMemo(
    () =>
      Object.values(projectsMap)
        .filter((mappedProject) => project.includes(mappedProject.id))
        .map((project) => ({
          value: project.id,
          label: `${project.displayId} - ${project.name}`,
        })),
    [projectsMap, project]
  );

  return (
    <MultiSelectDropdown
      options={projectOptions}
      filterable
      values={selectedProjectOptions}
      onChange={handleOnChange}
      onFilterChange={handleFilterChange}
      placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      triggerProps={{
        'aria-label': intl.get('ENTITIES.PROJECT', { num: 1 }),
      }}
    />
  );
};

export default ProjectFilterField;
