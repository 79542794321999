import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import {
  PROJECT_USER_ACTIONS,
  PROJECT_PERMISSIONS_BY_LEVEL,
  USER_TYPES,
} from 'utils/constants';
import {
  fetchCapacity,
  selectParticipantToUpdate,
  resetCapacityTable,
  selectPagination,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { selectUserId, selectUserType } from 'state/User/userSlice';
import { getCurrentProjectData } from 'state/Project/projectSlice';
import {
  getSingleTaskId,
  setTaskId,
  isCurrentUserAssignedToTask,
} from 'state/SingleTask/singleTaskSlice';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import useGetProjectReadOnly from 'Pages/ProjectPage/hooks/useGetProjectReadOnly';
import TaskSidePanel from 'Organisms/TaskSidePanel/TaskSidePanel';
import AddParticipantModal from './components/AddParticipantModal/AddParticipantModal';
import CapacityTable from './components/CapacityTable/CapacityTable';
import {
  fetchTeamTasks,
  fetchUserTasks,
} from 'state/ProjectTasks/projectTaskSlice';

const TasksAssignmentsResourcesCapacity = () => {
  const dispatch = useDispatch();
  const { canUser } = useUserPermissionsContext();
  const projectData = useSelector(getCurrentProjectData);
  const participantToUpdate = useSelector(selectParticipantToUpdate);
  const pagination = useSelector(selectPagination);
  const [displayAddParticipantModal, setDisplayAddParticipantModal] =
    useState<boolean>(false);
  const userType = useSelector(selectUserType);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;
  const currentUserId = useSelector(selectUserId);
  const isCurrentFacilitatorUserProjectOwner =
    isFacilitator && projectData.owners?.includes(currentUserId);
  const isReadOnly =
    useGetProjectReadOnly(projectData.status, projectData.is_archived) ||
    (isFacilitator && !isCurrentFacilitatorUserProjectOwner);

  const activeTaskId = useSelector(getSingleTaskId);
  const isUserAssignedToTask = useSelector(isCurrentUserAssignedToTask);
  const { permissionsLevel } = useHasUserAccess();
  const availableUserActions = useMemo(
    () => PROJECT_PERMISSIONS_BY_LEVEL[permissionsLevel],
    [permissionsLevel]
  );

  const init = useCallback(async () => {
    const projectStartDate = moment(new Date(projectData.startDate!)).startOf(
      'isoWeek'
    );
    const tableStartDate = moment(projectStartDate)
      .subtract(12, 'weeks')
      .format('YYYY-MM-DD');
    const tableEndDate = projectStartDate
      .clone()
      .add(12, 'weeks')
      .format('YYYY-MM-DD');
    await dispatch(
      fetchCapacity({
        projectId: projectData.id,
        query: { tableStartDate, tableEndDate },
      })
    );
  }, [projectData.id, dispatch, projectData.startDate]);

  const onSaveSidePanel = async () => {
    const { from, index } = pagination;
    if (from !== null && index) {
      const currentPaginationStartDate = moment(
        new Date(from.replace(/-/g, '/'))
      );
      const pivotDate = currentPaginationStartDate.add(index, 'weeks');
      let tableStartDate = pivotDate.clone().subtract(12, 'weeks');
      let tableEndDate = pivotDate.clone().add(12, 'weeks');
      const formattedStartDate = tableStartDate
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      const formattedEndDate = tableEndDate
        .endOf('isoWeek')
        .format('YYYY-MM-DD');
      await dispatch(
        fetchCapacity({
          projectId: projectData.id,
          query: {
            tableStartDate: formattedStartDate,
            tableEndDate: formattedEndDate,
          },
          fromSidePanel: true,
        })
      );
    }

    if (projectData.id) {
      await Promise.all([
        dispatch(fetchUserTasks(projectData.id)),
        dispatch(fetchTeamTasks(projectData.id)),
      ]);
    }
  };

  useEffect(() => {
    if (projectData.id) {
      init();
    }
    return () => {
      dispatch(resetCapacityTable());
      dispatch(() => dispatch(setTaskId('')));
    };
  }, [projectData.id, init, dispatch]);

  useEffect(() => {
    if (participantToUpdate !== null && !displayAddParticipantModal) {
      setDisplayAddParticipantModal(true);
    }
  }, [participantToUpdate, displayAddParticipantModal]);

  return (
    <div className='mt-4'>
      <AddParticipantModal
        projectId={projectData.id}
        isModalOpen={displayAddParticipantModal}
        onCloseModal={() => setDisplayAddParticipantModal(false)}
        participant={
          participantToUpdate !== null ? participantToUpdate : undefined
        }
      />
      <TaskSidePanel
        taskId={activeTaskId}
        onClosePanel={() => dispatch(setTaskId(''))}
        currentUserId={currentUserId}
        permissionsLevel={permissionsLevel}
        availableUserActions={availableUserActions}
        isUserAssignedToTask={isUserAssignedToTask}
        onSave={onSaveSidePanel}
      />
      {canUser(PROJECT_USER_ACTIONS.ADD_PARTICIPANT) && (
        <div className='absolute top-0 right-0'>
          <Button
            className='mr-6'
            onClick={() => setDisplayAddParticipantModal(true)}
            data-cy='add-participant-button'
            disabled={isReadOnly}
          >
            {intl.get('PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.TITLE')}
          </Button>
        </div>
      )}
      <CapacityTable
        projectId={projectData.id}
        startDate={new Date(projectData.startDate!)}
        endDate={new Date(projectData.targetCompletionDate!)}
      />
    </div>
  );
};

export default TasksAssignmentsResourcesCapacity;
