import { useEffect, useCallback, useMemo, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { SidePanel } from '@getsynapse/design-system';
import { SLICE_STATUS, TASK_STATUS } from 'utils/constants';
import {
  selectTaskIdToEdit,
  setTaskIdToEdit,
  setShouldRefreshTasks as setShouldRefreshProjectTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { setShouldRefreshTasks as setShouldRefreshAllTasks } from 'state/TasksList/allTasks/allTasksSlice';
import {
  fetchTaskDetail,
  selectCanEdit,
  selectTaskSliceStatus,
  selectIsTaskDisabled,
  selectTaskDueDate,
  selectTaskProject,
  selectTaskStatus,
  resetState as resetTaskDetailState,
} from 'state/TaskDetail/taskSlice';
import { TaskDetailToUpdate } from 'types/store/taskDetail';
import useSidePanel from 'Hooks/useSidePanel';
import useUpdateTask from 'Pages/TaskDetailPage/hooks/useUpdateTask';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import { validateIfTaskDueDateIsPast } from 'Pages/TaskDetailPage/TaskDetailForm/helpers/helpers';
import HeaderElement from './HeaderElement/HeaderElement';
import SidePanelContent from './SidePanelContent/SidePanelContent';

const TaskDetailSidePanel: FC<{
  isCentralizedTask?: boolean;
}> = ({ isCentralizedTask = false }) => {
  const dispatch = useDispatch();
  const [showUnsavedChangesBanner, setShowUnsavedChangesBanner] =
    useState<boolean>(false);
  const taskProject = useSelector(selectTaskProject);
  const taskDueDate = useSelector(selectTaskDueDate);
  const canUpdateTask = useSelector(selectCanEdit);
  const isTaskDisabled = useSelector(selectIsTaskDisabled);
  const fetchTaskDetailStatus = useSelector(selectTaskSliceStatus);
  const taskIdToEdit = useSelector(selectTaskIdToEdit);
  const taskStatus = useSelector(selectTaskStatus);
  const isTaskOnHold = taskStatus === TASK_STATUS.ON_HOLD;
  const isTaskCompleted = taskStatus === TASK_STATUS.COMPLETED;
  const isTaskDueDatePast = useMemo(
    () => validateIfTaskDueDateIsPast(taskDueDate),
    [taskDueDate]
  );

  const closePanelCallback = useCallback(() => {
    dispatch(setTaskIdToEdit(null));
    dispatch(resetTaskDetailState());
  }, [dispatch]);

  const { openPanel, isPanelOpen, onClose, closePanel } =
    useSidePanel(closePanelCallback);

  useEffect(() => {
    if (taskIdToEdit) {
      dispatch(fetchTaskDetail({ taskId: taskIdToEdit }));
      openPanel();
    }
  }, [taskIdToEdit, dispatch, openPanel]);

  const {
    SnackbarNotification,
    snackbarProps,
    isNotificationVisible,
    showNotification,
  } = useSnackbarNotification();

  const onSuccessCallback = useCallback(
    async (newTaskDetail: TaskDetailToUpdate) => {
      const tastName = newTaskDetail?.name!;
      if (isCentralizedTask) {
        dispatch(setShouldRefreshAllTasks(true));
      } else {
        dispatch(setShouldRefreshProjectTasks(true));
      }
      showNotification({
        notificationTitle: '',
        notificationMessage: intl
          .get('TASKS.TASK_DETAIL_PAGE.UPDATE_SUCCESSFUL')
          .replace('Task', tastName),
      });
      setShowUnsavedChangesBanner(false);
      closePanel();
    },
    [dispatch, closePanel, isCentralizedTask, showNotification]
  );

  const {
    task,
    requiredFieldsErrors,
    canSubmitChanges,
    hasAssignedUsers,
    updateTaskDetail,
    updateTaskAssignees,
    updateTaskDateRange,
    updateTaskStatus,
    handleUpdateTask,
  } = useUpdateTask({
    showNotification,
    onSuccessCallback,
  });

  const confirmIfCanClosePanel = useCallback(() => {
    if (canSubmitChanges) {
      setShowUnsavedChangesBanner(true);
    } else {
      closePanel();
    }
  }, [canSubmitChanges, closePanel]);

  const footerButtons = useMemo(
    () => [
      {
        children: intl.get('UPDATE'),
        disabled: !canUpdateTask || !canSubmitChanges,
        onClick: handleUpdateTask,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [closePanel, canUpdateTask, handleUpdateTask, canSubmitChanges]
  );

  return (
    <>
      <SidePanel
        isOpen={isPanelOpen}
        onClose={confirmIfCanClosePanel}
        footerButtons={footerButtons}
        isLoading={fetchTaskDetailStatus === SLICE_STATUS.LOADING}
        headerElement={
          <HeaderElement
            taskId={taskIdToEdit}
            projectId={taskProject?.id ?? ''}
            status={taskStatus}
            onClose={onClose}
          />
        }
      >
        {taskIdToEdit && (
          <SidePanelContent
            task={task}
            project={taskProject}
            requiredFieldsErrors={requiredFieldsErrors}
            hasAssignedUsers={hasAssignedUsers}
            canUpdateTask={canUpdateTask}
            isTaskDisabled={isTaskDisabled}
            isTaskOnHold={isTaskOnHold}
            isTaskDueDatePast={!isTaskCompleted && isTaskDueDatePast}
            updateTaskDetail={updateTaskDetail}
            updateTaskAssignees={updateTaskAssignees}
            updateTaskDateRange={updateTaskDateRange}
            updateTaskStatus={updateTaskStatus}
            showUnsavedChangesBanner={showUnsavedChangesBanner}
          />
        )}
      </SidePanel>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TaskDetailSidePanel;
