import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import {
  fetchTeamTasks,
  selectTeamTasksLoading,
  selectTeamTasks,
  selectCanFetchtMoreTasks,
  selectTotalCount,
  reorderTask,
  updateTasksProjectOrder,
  setFilters,
  setSearchFilter,
  selectTeamTasksTableFilters,
} from 'state/ProjectTasksList/teamTasks/teamTasksSlice';
import { fetchTaskTypes } from 'state/TasksList/allTasks/allTasksSlice';
import {
  setShouldRefreshTasks,
  selectShouldRefreshTasks,
  updateProjectTaskOrder,
  setSnackBarNotification,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { fetchProjectProgress } from 'state/Project/progress/progressSlice';
import { exportTasksList } from 'api/projectTasks';
import { TaskListFilters } from 'types/store/tasksList';
import { TASKS_TABLE_TABS, SLICE_STATUS } from 'utils/constants';
import {
  extractFilenameFromContentDisposition,
  createDownloadLinkAndDownloadFile,
} from 'utils/csvExports';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import TasksTable from '../TasksTable/TasksTable';

const TeamTasks = () => {
  const dispatch = useDispatch();
  const teamTasksTableFilters = useSelector(selectTeamTasksTableFilters);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchTeamTasksStatus = useSelector(selectTeamTasksLoading);
  const teamTasks = useSelector(selectTeamTasks);
  const canFetchMoreTasks = useSelector(selectCanFetchtMoreTasks);
  const totalCount = useSelector(selectTotalCount);
  const shouldRefreshTasks = useSelector(selectShouldRefreshTasks);
  const { projectId } = useParams<{ projectId: string }>();

  const {
    SnackbarNotification,
    isNotificationVisible,
    showNotification,
    snackbarProps,
  } = useSnackbarNotification();

  const init = useCallback(
    async (projectId: string) => {
      await dispatch(fetchFilterSettingByType(TASKS_TABLE_TABS.TEAM_TASKS));
      dispatch(fetchTeamTasks({ projectId, includeTotalCount: true }));
      dispatch(fetchTaskTypes());
      dispatch(fetchProjectProgress({ projectId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (projectId) {
      init(projectId);
    }
  }, [init, projectId]);

  useEffect(() => {
    if (shouldRefreshTasks) {
      dispatch(setShouldRefreshTasks(false));
      init(projectId);
    }
  }, [shouldRefreshTasks, init, dispatch, projectId]);

  useEffect(() => {
    if (fetchTeamTasksStatus) {
      if (fetchTeamTasksStatus === SLICE_STATUS.IDLE && isLoading) {
        setIsLoading(false);
      }
    }
  }, [fetchTeamTasksStatus, isLoading]);

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchTeamTasks({
        projectId,
        fetchNext: true,
      })
    );
  }, [dispatch, projectId]);

  const handleReorderTask = useCallback(
    async (taskId: string, startIndex: number, endIndex: number) => {
      const task = teamTasks[endIndex];
      const newOrder = parseInt(task.projectOrder, 10);
      if (isNaN(newOrder)) {
        dispatch(
          setSnackBarNotification({
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'TASKS.NOTIFICATIONS.REORDER_FAILED.TITLE'
            ),
            notificationMessage: intl.get(
              'TASKS.NOTIFICATIONS.REORDER_FAILED.MESSAGE'
            ),
          })
        );
        return;
      }
      dispatch(
        reorderTask({
          taskId,
          startIndex,
          endIndex,
        })
      );
      try {
        await dispatch(
          updateProjectTaskOrder({
            projectId,
            taskId,
            newOrder: parseInt(task.projectOrder),
          })
        );
        dispatch(updateTasksProjectOrder({ startIndex, endIndex, newOrder }));
      } catch (error) {
        dispatch(
          reorderTask({
            taskId,
            startIndex: endIndex,
            endIndex: startIndex,
          })
        );
      }
    },
    [dispatch, projectId, teamTasks]
  );

  const handleUpdateFilters = useCallback(
    (updatedFilters: TaskListFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchTeamTasks({
          projectId,
          fetchNext: false,
          includeTotalCount: true,
        })
      );
    },
    [dispatch, projectId]
  );

  const handleSearchFilterChange = useCallback(
    (value: string) => {
      dispatch(setSearchFilter(value));
      dispatch(
        fetchTeamTasks({
          projectId,
          fetchNext: false,
          includeTotalCount: true,
        })
      );
    },
    [dispatch, projectId]
  );

  const handleExportTasks = useCallback(async () => {
    try {
      const response = await exportTasksList({
        ...teamTasksTableFilters,
        projectId,
      });
      const filename = extractFilenameFromContentDisposition(
        response.headers['content-disposition']
      );
      createDownloadLinkAndDownloadFile(response.data, filename);
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.TITLE'),
        notificationMessage: intl.get(
          'TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.MESSAGE'
        ),
      });
    }
  }, [teamTasksTableFilters, projectId, showNotification]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${TASKS_TABLE_TABS.TEAM_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <>
      <TasksTable
        tableName={TASKS_TABLE_TABS.TEAM_TASKS}
        tasks={teamTasks}
        canFetchMoreTasks={canFetchMoreTasks}
        fetchMoreTasks={handleFetchMoreTasks}
        totalCount={totalCount}
        canReorderTasks
        reorderTask={handleReorderTask}
        filters={teamTasksTableFilters}
        onUpdateFilters={handleUpdateFilters}
        onExport={handleExportTasks}
        onSearchFilterChange={handleSearchFilterChange}
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TeamTasks;
