import intl from 'react-intl-universal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormItem,
  Tooltip,
  UsersPicker,
  IconButton,
} from '@getsynapse/design-system';
import {
  fetchStrategyOwners,
  selectActiveGoalsOwners,
} from 'state/StrategyGoals/StrategyGoalsSlice';

const GoalOwner = () => {
  const dispatch = useDispatch();

  const strategyOwners = useSelector(selectActiveGoalsOwners);

  useEffect(() => {
    dispatch(fetchStrategyOwners());
  }, [dispatch]);

  return (
    <FormItem
      label={
        <div className='flex justify-center items-center'>
          {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.OWNER')}
          <span className='text-error-dark'>*</span>
          <Tooltip
            trigger={
              <IconButton
                name='information-circle'
                className='w-4 h-4 text-neutral -top-1 relative'
                iconClassname='pointer-events-none'
              />
            }
            className='ml-1'
            openMode='hover1'
            contentProps={{
              className: 'z-50',
            }}
            timeout={0}
          >
            {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.OWNER_TOOLTIP')}
          </Tooltip>
        </div>
      }
      className='mb-4'
    >
      <UsersPicker
        usersList={strategyOwners}
        onChange={() => {}}
        triggerProps={{
          'data-testid': 'owner_dropdown',
        }}
        multiple={false}
        selectedUsersList={strategyOwners.length ? [strategyOwners[0]] : []}
      />
    </FormItem>
  );
};

export default GoalOwner;
