import { FC } from 'react';
import {
  tailwindOverride,
  Tooltip,
  Icon,
  Typography,
} from '@getsynapse/design-system';
import { RelationshipType } from 'types/store/taskDependencies';

export type RelationshipTypeListItem = {
  value: RelationshipType;
  label: string;
  tootip?: string;
};

interface RelationshipTypeOptionProps {
  option: RelationshipTypeListItem;
  isSelected: boolean;
  selectOption: (option: RelationshipTypeListItem) => void;
  className?: string;
}

const RelationshipTypeOption: FC<RelationshipTypeOptionProps> = ({
  option,
  isSelected,
  selectOption,
  className,
  ...otherProps
}) => {
  return (
    <li
      {...otherProps}
      key={option.value}
      className={tailwindOverride(
        'group flex flex-1 justify-between items-center',
        className,
        {
          'group-hover:bg-primary focus-visible:bg-primary bg-primary text-neutral-white':
            isSelected,
        }
      )}
      onClick={() => selectOption(option)}
    >
      {option.label}
      {option.tootip && (
        <Tooltip
          openMode='hover2'
          contentProps={{
            className: 'bg-neutral-darker z-50 relative p-3 -mr-4 mt-0.5',
          }}
          timeout={0}
          usePortal
          showPopper
          position='bottomLeft'
          trigger={
            <div className='flex h-full items-center' aria-label='info-icon'>
              <Icon
                name='information-circle'
                className={tailwindOverride('text-neutral text-2xl', {
                  'text-neutral-lighter': isSelected,
                })}
              />
            </div>
          }
        >
          <Icon
            name='caret-up'
            className={tailwindOverride(
              'text-lg text-neutral-darker',
              'absolute top-0 right-0',
              '-mt-3 mr-5'
            )}
          />
          <Typography variant='label' className='text-neutral-white'>
            {option.tootip}
          </Typography>
        </Tooltip>
      )}
    </li>
  );
};

export default RelationshipTypeOption;
