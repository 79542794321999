import { tailwindOverride } from '@getsynapse/design-system';

const LinkedRequestsSkeletonLoader = () => {
  const TableCell = ({ widthClass }: { widthClass: string }) => (
    <td className={`${widthClass} h-10 px-2`}>
      <div className='w-full h-4 bg-primary-lighter-two rounded animate-pulse' />
    </td>
  );

  const TableRow = ({ isOdd }: { isOdd: boolean }) => (
    <tr
      className={tailwindOverride({
        'bg-neutral-lightest-two': isOdd,
        'bg-neutral-white': !isOdd,
      })}
    >
      <TableCell widthClass='w-1/4' />
      <TableCell widthClass='w-1/4' />
      <TableCell widthClass='w-1/4' />
      <TableCell widthClass='w-1/6' />
      <TableCell widthClass='w-1/6' />
    </tr>
  );

  return (
    <div className='w-full'>
      <table className='w-full border border-neutral-lighter-two rounded'>
        <thead className='bg-primary-lightest'>
          <tr>
            <th className='w-1/4 h-10 px-2'>
              <div className='w-3/4 h-4 bg-primary-lighter-two rounded animate-pulse' />
            </th>
            <th className='w-1/4 h-10 px-2'>
              <div className='w-3/4 h-4 bg-primary-lighter-two rounded animate-pulse' />
            </th>
            <th className='w-1/4 h-10 px-2'>
              <div className='w-3/4 h-4 bg-primary-lighter-two rounded animate-pulse' />
            </th>
            <th className='w-1/6 h-10 px-2'>
              <div className='w-3/5 h-4 bg-primary-lighter-two rounded animate-pulse' />
            </th>
            <th className='w-1/6 h-10 px-2'>
              <div className='w-3/5 h-4 bg-primary-lighter-two rounded animate-pulse' />
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(4)].map((_, index) => (
            <TableRow key={index} isOdd={index % 2 !== 0} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LinkedRequestsSkeletonLoader;
