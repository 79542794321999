import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { UserOption } from '@getsynapse/design-system/dist/Molecules/UsersPicker/UsersPicker';
import { TaskDetailToUpdate } from 'types/store/taskDetail';
import { TaskAssignee } from 'types/store/tasks';
import { rangeDate } from 'utils/customTypes';
import { TASK_STATUS, SLICE_STATUS } from 'utils/constants';
import {
  compareDates,
  formatDate,
  getTakDetailsToUpdate,
  validateRequiredTaskFields,
} from '../TaskDetailForm/helpers/helpers';
import {
  updateTaskDetail as updateTask,
  selectTaskInformation,
  setStatus,
} from 'state/TaskDetail/taskSlice';

export interface UseUpdateTaskParams {
  showNotification: (params: any) => void;
  onSuccessCallback?: (newTaskDetail: TaskDetailToUpdate) => void;
}

interface UseUpdateTaskReturn {
  task: TaskDetailToUpdate;
  requiredFieldsErrors: Map<string, boolean>;
  canSubmitChanges: boolean;
  hasAssignedUsers: boolean;
  updateTaskDetail: (
    key: keyof TaskDetailToUpdate,
    value: string | undefined
  ) => void;
  updateTaskDateRange: (range: rangeDate) => void;
  updateTaskAssignees: (assignees: UserOption[]) => void;
  updateTaskStatus: (status: string) => void;
  handleUpdateTask: () => void;
}

const useUpdateTask: (params: UseUpdateTaskParams) => UseUpdateTaskReturn = ({
  showNotification,
  onSuccessCallback = () => {},
}) => {
  const dispatch = useDispatch();
  const originalTaskDetail = useSelector(selectTaskInformation);
  const [taskDetail, setTaskDetail] = useState<TaskDetailToUpdate>(
    {} as TaskDetailToUpdate
  );
  const [canSubmitChanges, setCanSubmitChanges] = useState<boolean>(false);
  const [requiredFieldsErrors, setRequiredFieldsErrors] = useState(
    new Map<string, boolean>()
  );

  const taskAssigneesMap = useMemo<Map<string, TaskAssignee>>(() => {
    const assigneesMap = new Map<string, TaskAssignee>();
    originalTaskDetail?.assignees.forEach((assignee) => {
      assigneesMap.set(assignee.id, assignee);
    });
    return assigneesMap;
  }, [originalTaskDetail?.assignees]);

  const changesDetected = useMemo(() => {
    if (taskDetail.name !== originalTaskDetail?.name) {
      return true;
    }

    if (taskDetail.description !== originalTaskDetail?.description) {
      return true;
    }

    if (taskDetail.type !== originalTaskDetail?.type) {
      return true;
    }

    if (taskDetail.status !== originalTaskDetail?.status) {
      return true;
    }

    if (taskDetail.estimateHours !== originalTaskDetail?.estimateHours) {
      return true;
    }

    if (taskDetail.assignees) {
      if (
        taskDetail.assignees.toAdd.length > 0 ||
        taskDetail.assignees.toRemove.length > 0
      ) {
        return true;
      }
    }

    if (compareDates(originalTaskDetail?.startDate, taskDetail.startDate)) {
      return true;
    }

    if (compareDates(originalTaskDetail?.endDate, taskDetail.dueDate)) {
      return true;
    }

    if (
      compareDates(originalTaskDetail?.completedDate, taskDetail.completionDate)
    ) {
      return true;
    }

    return false;
  }, [originalTaskDetail, taskDetail]);

  const hasAssignedUsers =
    taskAssigneesMap.size > 0 ||
    (taskDetail.assignees ? taskDetail.assignees.toAdd.length > 0 : false);

  const updateTaskDateRange = useCallback((range: rangeDate) => {
    let startDate = range.startDate;
    let endDate = range.endDate;

    if (startDate) {
      startDate = formatDate(startDate);
    }

    if (endDate) {
      endDate = formatDate(endDate);
    }

    setTaskDetail((prev) => ({
      ...prev,
      startDate: startDate as string,
      dueDate: endDate as string,
    }));
  }, []);

  const updateTaskAssignees = useCallback(
    (assignees: UserOption[]) => {
      const updatedAssigneesIds = assignees.map((assignee) => assignee.value);
      const previousAssigneesIds = Array.from(taskAssigneesMap.keys());

      const toAdd = updatedAssigneesIds.filter(
        (id) => !previousAssigneesIds.includes(id)
      );
      const toRemove = previousAssigneesIds.filter(
        (id) => !updatedAssigneesIds.includes(id)
      );

      setTaskDetail((prev) => ({
        ...prev,
        assignees: {
          toAdd,
          toRemove,
        },
      }));
    },
    [taskAssigneesMap]
  );

  const updateTaskStatus = useCallback(
    (status: string) => {
      const taskDetailToUpdate = { ...taskDetail };
      if (
        taskDetailToUpdate.status === TASK_STATUS.COMPLETED &&
        status !== TASK_STATUS.COMPLETED
      ) {
        taskDetailToUpdate.completionDate = undefined;
      }

      if (
        taskDetailToUpdate.status !== TASK_STATUS.COMPLETED &&
        status === TASK_STATUS.COMPLETED
      ) {
        taskDetailToUpdate.completionDate = formatDate(new Date());
      }

      setTaskDetail((prevState) => ({
        ...prevState,
        ...taskDetailToUpdate,
        status,
      }));
    },
    [taskDetail]
  );

  const updateTaskDetail = useCallback(
    (key: keyof TaskDetailToUpdate, value: string | undefined) => {
      setTaskDetail((prevState: TaskDetailToUpdate) => ({
        ...prevState,
        [key]: value,
      }));
    },
    []
  );

  const handleUpdateTask = useCallback(async () => {
    const requiredFieldsErrorsMap = validateRequiredTaskFields(
      taskDetail,
      hasAssignedUsers
    );
    setRequiredFieldsErrors(requiredFieldsErrorsMap);

    if (requiredFieldsErrorsMap.size > 0) {
      showNotification({
        notificationVariant: 'error',
        notificationMessage: intl.get(
          'TASKS.TASK_DETAIL_PAGE.TASK_UPDATE_ERROR'
        ),
      });
      return;
    }

    const difference = getTakDetailsToUpdate(originalTaskDetail!, taskDetail);
    try {
      await dispatch(updateTask({ task: difference }));
      setCanSubmitChanges(false);
      onSuccessCallback(taskDetail);
    } catch (error) {
      dispatch(setStatus(SLICE_STATUS.FAILED));
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get(
          'TASKS.TASK_DETAIL_PAGE.UPDATE_ERROR.TITLE'
        ),
        notificationMessage: intl.get(
          'TASKS.TASK_DETAIL_PAGE.UPDATE_ERROR.MESSAGE'
        ),
      });
    }
  }, [
    taskDetail,
    originalTaskDetail,
    dispatch,
    showNotification,
    hasAssignedUsers,
    onSuccessCallback,
  ]);

  useEffect(() => {
    if (changesDetected && !canSubmitChanges) {
      setCanSubmitChanges(true);
    }

    if (!changesDetected && canSubmitChanges) {
      setCanSubmitChanges(false);
    }
  }, [changesDetected, canSubmitChanges]);

  useEffect(() => {
    if (originalTaskDetail) {
      setTaskDetail({
        id: originalTaskDetail.id,
        name: originalTaskDetail?.name,
        description: originalTaskDetail?.description,
        status: originalTaskDetail?.status,
        type: originalTaskDetail?.type,
        estimateHours: originalTaskDetail?.estimateHours,
        disabled: originalTaskDetail?.disabled,
        completionDate: originalTaskDetail?.completedDate
          ? formatDate(originalTaskDetail.completedDate)
          : undefined,
        assignees: {
          toAdd: [],
          toRemove: [],
        },
      });
      updateTaskDateRange({
        startDate: originalTaskDetail?.startDate ?? '',
        endDate: originalTaskDetail?.endDate ?? '',
      });
    }

    return () => {
      setTaskDetail({} as TaskDetailToUpdate);
      setRequiredFieldsErrors(new Map<string, boolean>());
      setCanSubmitChanges(false);
    };
  }, [originalTaskDetail, updateTaskDateRange]);

  return useMemo(
    () => ({
      task: taskDetail,
      requiredFieldsErrors,
      canSubmitChanges,
      hasAssignedUsers,
      updateTaskDateRange,
      updateTaskAssignees,
      updateTaskDetail,
      updateTaskStatus,
      handleUpdateTask,
    }),
    [
      taskDetail,
      requiredFieldsErrors,
      canSubmitChanges,
      hasAssignedUsers,
      updateTaskDateRange,
      updateTaskAssignees,
      updateTaskDetail,
      updateTaskStatus,
      handleUpdateTask,
    ]
  );
};

export default useUpdateTask;
