import { FC } from 'react';
import intl from 'react-intl-universal';
import { FormItem } from '@getsynapse/design-system';
import { NewTask } from 'types/store/newTask';
import { rangeDate } from 'utils/customTypes';
import TaskTypeField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskType';
import TaskDatesField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskDates';
import TaskEstimatedTimeField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskEstimateHours';
import ProjectPicker from '../../TasksFiltersSidePanel/FiltersForm/ProjectFilterField';
import AssigneesPicker from '../../TasksFiltersSidePanel/FiltersForm/AssigneeFilterField';
import TaskDescriptionField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskDescription';
import TaskNameField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskName';

interface TaskFormFieldsProps {
  taskData: NewTask;
  updateTaskData: (key: keyof NewTask, value: NewTask[keyof NewTask]) => void;
  displayProjectPicker?: boolean;
  displayDateRange?: boolean;
}

const TaskFormFields: FC<TaskFormFieldsProps> = ({
  taskData,
  updateTaskData,
  displayDateRange = true,
  displayProjectPicker = true,
}) => {
  const handleDateRangeChange = (rangeDate: rangeDate) => {
    if (rangeDate.startDate) {
      updateTaskData(
        'startDate',
        new Date(rangeDate.startDate).toLocaleDateString('en-US')
      );
    }
    if (rangeDate.endDate) {
      updateTaskData(
        'dueDate',
        new Date(rangeDate.endDate).toLocaleDateString('en-US')
      );
    }
    if (rangeDate.startDate && !rangeDate.endDate) {
      updateTaskData('dueDate', '');
    }
  };

  return (
    <div className='mt-4 flex w-full gap-x-10 px-px'>
      <div className='grid gap-y-4 w-2/4'>
        <TaskNameField
          name={taskData.name ?? ''}
          onNameChange={(name: string) => {
            updateTaskData('name', name);
          }}
        />
        <TaskTypeField
          type={taskData.type}
          onChangeType={(type: string) => {
            updateTaskData('type', type);
          }}
        />
        {displayDateRange && (
          <TaskDatesField
            hasAssignedUsers={
              taskData.assignees ? taskData.assignees.length > 0 : false
            }
            startDate={
              taskData.startDate ? new Date(taskData.startDate) : undefined
            }
            dueDate={taskData.dueDate ? new Date(taskData.dueDate) : undefined}
            onDateChange={handleDateRangeChange}
          />
        )}
        <TaskEstimatedTimeField
          estimateHours={taskData.estimateHours}
          onEstimateHoursChange={(estimateHours: string) => {
            updateTaskData('estimateHours', estimateHours);
          }}
        />
      </div>
      <div className='grid gap-y-4 w-2/4'>
        {displayProjectPicker && (
          <FormItem
            label={intl.get('ENTITIES.PROJECT', { num: 1 })}
            labelProps={{ required: true }}
          >
            <ProjectPicker
              project={[taskData.projectId]}
              selectedPrograms={[]}
              isFiltering={false}
              onChange={(project: string[]) => {
                updateTaskData('projectId', project[0]);
              }}
            />
          </FormItem>
        )}
        <TaskDescriptionField
          description={taskData.description ?? ''}
          onDescriptionChange={(description: string) => {
            updateTaskData('description', description);
          }}
          shouldExpand2Rows={displayProjectPicker}
        />
        <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ASSIGNEE')}>
          <AssigneesPicker
            user={taskData.assignees ?? []}
            onChange={(user: string[]) => {
              updateTaskData('assignees', user);
            }}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default TaskFormFields;
