import { FC } from 'react';
import { Tag, Typography } from '@getsynapse/design-system';
import { Option } from 'utils/customTypes';

interface SelectedOptionsListProps {
  selectedOptions: Option[];
  isDropdownExpanded: boolean;
  removeSelectedOption?: (option: Option) => void;
  limitTags: number;
}

const SelectedTaskOptions: FC<SelectedOptionsListProps> = ({
  selectedOptions,
  isDropdownExpanded,
  removeSelectedOption,
  limitTags,
}) => {
  const slicedOption = !isDropdownExpanded
    ? selectedOptions.slice(0, limitTags)
    : selectedOptions;
  const remainingOptions = selectedOptions.length - limitTags;
  return (
    <>
      {slicedOption.map((option) => (
        <Tag
          key={option.value}
          label={option.label}
          onClose={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            removeSelectedOption && removeSelectedOption(option);
          }}
          color='default'
        />
      ))}
      {!isDropdownExpanded && remainingOptions > 0 && (
        <Typography
          className='text-neutral ml-1'
          variant='body2'
          weight='medium'
        >
          {`+${remainingOptions} more`}
        </Typography>
      )}
    </>
  );
};

export default SelectedTaskOptions;
