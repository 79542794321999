import Auth from '@aws-amplify/auth';
import axios, { AxiosError } from 'axios';
import config from 'config/Config';
import { PATHS } from 'utils/constants';

let user: { signInUserSession: { idToken: { jwtToken: string } } };

export const getUserToken = async () => {
  const token = user.signInUserSession.idToken.jwtToken;
  return token;
};

export const initAxios = (baseURL: string) => {
  const instance = axios.create({ baseURL });
  instance.interceptors.request.use(async (config) => {
    try {
      user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      config.headers.common['Authorization'] = `bearer ${token}`;
    } catch (error) {
      config.headers.common['Authorization'] = undefined;
    }

    try {
      config.headers.common['time-zone'] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      config.headers.common['time-zone'] = undefined;
    }

    return config;
  });
  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (error.response?.status === 401) {
        //! TODO: replace this for an error boundry flow to be handled in react
        if (window.location.pathname !== PATHS.SSO_AUTHENTICATION_CALLBACK) {
          await Auth.signOut({ global: true });
        } else {
          window.location.href = config.get('authentication.redirectSignOut');
        }
      }
      if (error.response?.status === 404) {
        return Promise.reject(error.response.status);
      }
      if (error.response?.status === 403) {
        return Promise.reject(error.response.status);
      }
      throw error;
    }
  );
  return instance;
};
