import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Button,
  Icon,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import {
  fetchTaskContent,
  selectIsFetchingLinkedTaskContent,
} from 'state/LinkedTaskContent/linkedTaskContentSlice';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import useLinkedTaskContent from './hooks/useLinkedTaskContent';
import LinkedContentCardsList from './components/LinkedContentCardsList';
import AddContentModal from 'Pages/ProjectPage/tabs/Content/components/AddContentModal/AddContentModal';
import LinkProjectContentModal from './components/LinkProjectContentModal/LinkProjectContentModal';

interface LinkedContentProps {
  taskId: string;
  projectId: string;
  canUpdateContent?: boolean;
  isReadOnly?: boolean;
  aligCtaButtonsToRight?: boolean;
}

const LinkedContent: FC<LinkedContentProps> = ({
  taskId,
  projectId,
  canUpdateContent = true,
  isReadOnly = false,
  aligCtaButtonsToRight = false,
}) => {
  const dispatch = useDispatch();
  const [displayAddContentModal, setDisplayAddContentModal] =
    useState<boolean>(false);
  const [displayLinkContentModal, setDisplayLinkContentModal] =
    useState<boolean>(false);
  const isFetchingLinkedTaskContent = useSelector(
    selectIsFetchingLinkedTaskContent
  );

  useEffect(() => {
    dispatch(fetchTaskContent(taskId));
  }, [taskId, dispatch]);

  const {
    isNotificationVisible,
    showNotification,
    SnackbarNotification,
    snackbarProps,
  } = useSnackbarNotification();

  const { addTaskContent, removeTaskContent, linkContentFiles } =
    useLinkedTaskContent({
      taskId,
      projectId,
      showNotification,
    });

  if (isFetchingLinkedTaskContent) {
    return <div>Loading...</div>;
  }

  return (
    <div className={tailwindOverride('w-full py-8', 'flex flex-col gap-y-6')}>
      <AddContentModal
        projectId={projectId}
        isOpen={displayAddContentModal}
        setModalOpen={setDisplayAddContentModal}
        handleUploadOrLinkFiles={addTaskContent}
        canLinkTasks={false}
      />
      <LinkProjectContentModal
        projectId={projectId}
        isOpen={displayLinkContentModal}
        onClose={() => setDisplayLinkContentModal(false)}
        linkContentFiles={linkContentFiles}
        unLinkContentFiles={removeTaskContent}
      />
      <div
        className={tailwindOverride('w-full flex', {
          'flex-col gap-y-4': !aligCtaButtonsToRight,
          'justify-between items-center': aligCtaButtonsToRight,
        })}
      >
        <div className='flex flex-col gap-y-2'>
          <Typography variant='h5' className='text-primary'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.TITLE')}
          </Typography>
          <Typography variant='label' className='text-neutral'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.SUBTITLE')}
          </Typography>
        </div>
        <div
          className={tailwindOverride('flex items-center gap-x-2', {
            hidden: !canUpdateContent,
          })}
        >
          <Button
            variant='tertiary'
            className='mr-2'
            onClick={() => setDisplayLinkContentModal(true)}
            data-cy='link-content-button'
            disabled={isReadOnly}
          >
            <Icon name='link' className='text-lg mr-2.5' />
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT')}
          </Button>
          <Button
            variant='tertiary'
            onClick={() => setDisplayAddContentModal(true)}
            disabled={isReadOnly}
            className='flex items-center gap-x-2 h-8'
          >
            <Icon name='add-circle' className='text-lg' />
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.ADD_CONTENT')}
          </Button>
        </div>
      </div>
      <LinkedContentCardsList
        unlinkContent={(contentId: string) => {
          removeTaskContent([contentId]);
        }}
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </div>
  );
};

export default LinkedContent;
