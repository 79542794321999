import { FC } from 'react';
import intl from 'react-intl-universal';
import { Button, tailwindOverride } from '@getsynapse/design-system';

interface ActionsButtonsProps {
  onAdd: () => void;
  onCancel: () => void;
  shouldEnableAddButton?: boolean;
}

const ActionsButtons: FC<ActionsButtonsProps> = ({
  onAdd,
  onCancel,
  shouldEnableAddButton = false,
}) => {
  return (
    <div
      className={tailwindOverride(
        'flex items-center gap-x-2 justify-end place-self-end'
      )}
    >
      <Button
        disabled={!shouldEnableAddButton}
        onClick={onAdd}
        className='py-3'
      >
        {intl.get('ADD')}
      </Button>
      <Button variant='secondary' onClick={onCancel} className='py-3'>
        {intl.get('CANCEL')}
      </Button>
    </div>
  );
};

export default ActionsButtons;
