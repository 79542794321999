import { FC, useEffect, useRef, useState } from 'react';
import {
  Icon,
  useElevation,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Option } from 'utils/customTypes';
import SelectedTaskOptions from './SelectedTaskOptions';

export interface TasksPickerTriggerProps {
  onToggleDropdown: () => void;
  isDropdownExpanded: boolean;
  selectedOptions: Option[];
  filterValue?: string;
  disabled: boolean;
  readOnly?: boolean;
  onInputChange?: (value: string) => void;
  placeholder?: string;
  removeSelectedOption?: (option: Option) => void;
  limitTags: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const TasksPickerTrigger: FC<TasksPickerTriggerProps> = ({
  onToggleDropdown,
  isDropdownExpanded,
  disabled,
  readOnly,
  onInputChange,
  placeholder,
  selectedOptions,
  removeSelectedOption,
  limitTags,
  onKeyDown,
}) => {
  const hoverElevation = useElevation(1);
  const inputRef = useRef<HTMLInputElement>(null);
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    isDropdownExpanded && inputRef.current?.focus();
  }, [isDropdownExpanded]);

  return (
    <div
      role='button'
      onKeyDown={onKeyDown}
      onClick={onToggleDropdown}
      className={tailwindOverride(
        'h-auto rounded border flex items-center relative',
        'py-2 px-4 min-h-10 text-base',
        `hover:${hoverElevation} focus:border-primary`,
        'focus-visible:outline-none focus:ring-primary',
        'focus-visible:ring-primary bg-neutral-white',
        'cursor-default max-w-full space-x-4',
        {
          'ring-primary border-primary': isDropdownExpanded,
          'border-primary-lighter hover:border-neutral-lighter focus:border-primary active:primary-lighter text-neutral-black':
            !isDropdownExpanded,
        }
      )}
      aria-label={placeholder}
      aria-disabled={disabled || readOnly}
    >
      <div
        className={tailwindOverride(
          'w-full h-full',
          'flex flex-wrap items-center',
          'gap-2'
        )}
      >
        {selectedOptions.length > 0 && (
          <SelectedTaskOptions
            selectedOptions={selectedOptions}
            isDropdownExpanded={isDropdownExpanded}
            removeSelectedOption={removeSelectedOption}
            limitTags={limitTags}
          />
        )}
        <input
          type='text'
          ref={inputRef}
          value={filterValue}
          className={tailwindOverride(
            'form-input appearance-none w-max min-w-6 flex-grow h-6',
            'border-0 focus:ring-0 active:ring-0 p-0 overflow-ellipsis',
            'focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
            'placeholder-neutral bg-transparent text-neutral-black ml-1.5',
            { 'cursor-not-allowed': disabled || readOnly }
          )}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilterValue(event.target.value);
            onInputChange && onInputChange(event.target.value);
          }}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
        />
        <div className='flex w-4'>
          <Icon
            name={
              isDropdownExpanded ? 'caret-up-outline' : 'caret-down-outline'
            }
            className='text-neutral-dark'
          />
        </div>
      </div>
    </div>
  );
};

export default TasksPickerTrigger;
