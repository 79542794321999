import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS, USER_STATUS } from 'utils/constants';
import { RootState } from 'state/store';
import strategyGoalDetailAPI from './StrategyGoalDetailAPI';

interface StrategyGoalDetail {
  id: string;
  title: string;
  type: string;
  timePeriod: {
    type: string;
    year: number;
  };
  owners: {
    id: string;
    status: string;
    name: string;
    avatarUrl: string | null;
    initials: string;
    firstName: string;
    lastName: string;
  }[];
  teams: {
    id: string;
    name: string;
  }[];
  organizationId: string;
}

interface StrategyGoalDetailState {
  status: Status;
  value: StrategyGoalDetail;
  errorCode: number;
}

/* ============================= INITIAL STATE ============================== */

const initialState: StrategyGoalDetailState = {
  value: {
    id: '',
    title: '',
    type: '',
    timePeriod: {
      type: '',
      year: 2025,
    },
    owners: [],
    teams: [],
    organizationId: '',
  },
  errorCode: 0,
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const fetchStrategyGoalDetail = createAsyncThunk(
  'strategyGoalDetail/FETCH_STRATEGY_GOAL_DETAIL',
  async (goalId: string, { rejectWithValue }) => {
    try {
      const response = await strategyGoalDetailAPI.fetchStrategyGoalDetail(
        goalId
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* ================================= REDUCER ================================ */
const strategyGoalDetailSlice = createSlice({
  name: 'strategyGoalDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStrategyGoalDetail.pending, (state) => {
        state.errorCode = 0;
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchStrategyGoalDetail.rejected, (state, action) => {
        state.errorCode = action.payload as number;
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchStrategyGoalDetail.fulfilled, (state, action) => {
        state.value = action.payload;
        state.errorCode = 0;
        state.status = SLICE_STATUS.IDLE;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectStrategyGoalDetail = (state: RootState) =>
  state.strategyGoalDetail;

export const selectStrategyGoalDetailOwner = (state: RootState) => {
  const goalDetailOwner =
    state.strategyGoalDetail.value &&
    state.strategyGoalDetail.value.owners.map((owner) => ({
      label: owner.name,
      id: owner.id,
      firstName: owner.firstName,
      lastName: owner.lastName,
      status: owner.status,
      initials: owner.initials,
      avatar: owner.avatarUrl,
      disabled:
        owner.status === USER_STATUS.REGISTERED_DISABLED ||
        owner.status === USER_STATUS.INVITED_DISABLED,
    }));
  return goalDetailOwner && goalDetailOwner[0];
};

export default strategyGoalDetailSlice.reducer;
