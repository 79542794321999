import intl from 'react-intl-universal';
import { tailwindOverride } from '@getsynapse/design-system';
import { TaskStatus } from 'types/store/tasks';

export const getTaskStatusLabel = (status: TaskStatus): string => {
  return intl.get(
    `TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${status.toUpperCase()}`
  );
};

interface TaskStatusTagStyles {
  className: string;
  textClassName: string;
}

export const getTaskStatusTagStyles = (
  status: TaskStatus,
  selected?: boolean
): TaskStatusTagStyles => {
  let taskStatusTagStyles: TaskStatusTagStyles = {
    className: '',
    textClassName: '',
  };

  switch (status) {
    case TaskStatus.InProgress:
      taskStatusTagStyles = {
        className: tailwindOverride(
          'bg-teal-lightest group-hover:border-teal',
          {
            'border-teal': selected,
          }
        ),
        textClassName: 'text-teal-dark',
      };
      break;
    case TaskStatus.OnHold:
      taskStatusTagStyles = {
        className: tailwindOverride(
          'bg-tertiary-lightest group-hover:border-tertiary',
          { 'border-tertiary': selected }
        ),
        textClassName: 'text-tertiary-darker',
      };
      break;
    case TaskStatus.Completed:
      taskStatusTagStyles = {
        className: tailwindOverride(
          'bg-success-lightest group-hover:border-success-dark',
          { 'border-success-dark': selected }
        ),
        textClassName: 'text-success-darker',
      };
      break;
    default:
      taskStatusTagStyles = {
        className: tailwindOverride(
          'bg-purple-lightest group-hover:border-purple-dark',
          { 'border-purple-dark': selected }
        ),
        textClassName: 'text-purple-darker',
      };
  }

  return taskStatusTagStyles;
};
