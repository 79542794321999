import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import { ProjectContent } from 'utils/customTypes';
import projectAPI from 'state/Project/projectAPI';
import { addProjectFilesFromTask } from 'state/Project/projectSlice';
import { addContentFiles } from 'state/SingleTask/singleTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import AddContentModal from 'Pages/ProjectPage/tabs/Content/components/AddContentModal/AddContentModal';
import LinkContentModal from '../LinkContentModal/LinkContentModal';
import { PROJECT_STATUS } from 'utils/constants';
import { useSelector } from 'react-redux';
import { getCurrentProjectData } from 'state/Project/projectSlice';

const ContentSection: React.FC<{
  canUpdateContent?: boolean;
}> = ({ children, canUpdateContent = true }) => {
  const { projectId, taskId } = useParams<{
    projectId: string;
    taskId: string;
  }>();
  const dispatch = useDispatch();
  const [displayAddContentModal, setDisplayAddContentModal] = useState(false);
  const [displayLinkContentModal, setDisplayLinkContentModal] = useState(false);

  const handleAddContent = async (files: ProjectContent[]) => {
    const updatedFiles = files.map((file: ProjectContent) => {
      return {
        ...file,
        linkedTasksIds: [taskId],
      };
    });
    const response = await projectAPI.addProjectFiles(projectId, {
      projectFiles: updatedFiles,
    });
    batch(() => {
      dispatch(addProjectFilesFromTask(response));
      dispatch(addContentFiles(response));
    });
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'TASKS.TASK_DETAIL_PAGE.CONTENT.FILES_ADDED_MESSAGE'
        ),
      })
    );
  };

  const projectData = useSelector(getCurrentProjectData);

  const isReadOnly = [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
    projectData.status
  );

  return (
    <div className='mt-8'>
      <LinkContentModal
        isModalOpen={displayLinkContentModal}
        onModalClose={() => setDisplayLinkContentModal(false)}
        taskId={taskId}
      />
      <AddContentModal
        projectId={projectId}
        isOpen={displayAddContentModal}
        setModalOpen={setDisplayAddContentModal}
        handleUploadOrLinkFiles={handleAddContent}
        canLinkTasks={false}
      />
      <div className='flex justify-between items-center'>
        <div>
          <Typography variant='h5' className='text-primary'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.TITLE')}
          </Typography>
          <Typography variant='label' className='text-neutral'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.SUBTITLE')}
          </Typography>
        </div>
        <div
          className={tailwindOverride('flex', { hidden: !canUpdateContent })}
        >
          <Button
            variant='tertiary'
            className='mr-2'
            onClick={() => setDisplayLinkContentModal(true)}
            data-cy='link-content-button'
            disabled={isReadOnly}
          >
            <Icon name='link' className='text-lg mr-2.5' />
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT')}
          </Button>
          <Button
            variant='tertiary'
            onClick={() => setDisplayAddContentModal(true)}
            data-cy='add-content-button'
            disabled={isReadOnly}
          >
            <Icon name='add-circle' className='text-lg mr-2.5' />
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.ADD_CONTENT')}
          </Button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ContentSection;
