import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';

import RenderNoRecords from 'Atoms/NoRecords/NoRecords';

import { PATHS } from 'utils/constants';
import NoRequestsSvg from 'assets/icons/empty-requests.svg';

const NoRequestsTable = () => {
  return (
    <RenderNoRecords
      imageSrc={NoRequestsSvg}
      caption={intl.get('REQUESTS_LIST_PAGE.CREATE_MANAGE_REQUESTS')}
      className='h-empty-table-body'
    >
      <Link to={PATHS.REQUEST_PAGE}>
        <Button className='mt-2 mx-auto'>
          {intl.get('REQUESTS_LIST_PAGE.GET_STARTED')}
        </Button>
      </Link>
    </RenderNoRecords>
  );
};

export default NoRequestsTable;
