import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class StrategyGoalDetailAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/strategy/goals`);
  }

  fetchStrategyGoalDetail = async (goalId: string) => {
    const res = await this.instance.get(`/${goalId}`);
    return res;
  };
}

export default new StrategyGoalDetailAPI();
