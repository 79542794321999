import { FC } from 'react';
import intl from 'react-intl-universal';
import { Button, Icon, Tooltip, Typography } from '@getsynapse/design-system';

interface RemoveDependencyButtonProps {
  removeDependency: () => void;
}

const RemoveDependencyButton: FC<RemoveDependencyButtonProps> = ({
  removeDependency,
}) => {
  return (
    <Tooltip
      openMode='hover2'
      timeout={0}
      usePortal
      showPopper
      position='topCenter'
      contentProps={{
        className: 'bg-neutral-darker z-50 py-1 px-2',
      }}
      trigger={
        <div>
          <Button
            className=' px-1 py-0.5 justify-center'
            aria-label='remove-linked-task'
            variant='tertiary'
            onClick={removeDependency}
          >
            <Icon name='close-circle' className='text-neutral text-lg' />
          </Button>
        </div>
      }
    >
      <Typography variant='label' className='text-neutral-white'>
        {intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY')}
      </Typography>
    </Tooltip>
  );
};

export default RemoveDependencyButton;
