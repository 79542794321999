import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTeamTasks,
  selectTeamTasks,
} from 'state/ProjectTasks/projectTaskSlice';
import { Option } from 'utils/customTypes';
import TasksPicker from './TasksPicker';

interface LinkV1TasksProps {
  projectId: string;
  selectedTasks: Option[];
  setSelectedTasks: (tasks: Option[]) => void;
}

const LinkV1Tasks: FC<LinkV1TasksProps> = ({
  projectId,
  selectedTasks,
  setSelectedTasks,
}) => {
  const dispatch = useDispatch();
  const tasks = useSelector(selectTeamTasks);

  const tasksOptions = useMemo(() => {
    if (!tasks.data) {
      return [];
    }
    return tasks.data.map((task) => {
      return {
        label: task.name,
        value: task.id,
      };
    });
  }, [tasks]);

  useEffect(() => {
    dispatch(fetchTeamTasks(projectId));
  }, [dispatch, projectId]);

  return (
    <TasksPicker
      tasksOptions={tasksOptions}
      selectedTasks={selectedTasks}
      onSelectTasks={setSelectedTasks}
    />
  );
};

export default LinkV1Tasks;
