import { useState, useEffect, useCallback, useMemo } from 'react';
import { Option } from 'utils/customTypes';
import { LinkedTask } from 'types/store/taskDependencies';
import { FetchAvailableTasksResponse } from 'api/taskDependencies';

interface UseTasksPickerParams {
  onSelectTasks: (tasks: string[]) => void;
  onSearchTasks: (name: string) => Promise<FetchAvailableTasksResponse>;
}

interface AvailabeTaskOption extends Option {
  disabled?: boolean;
}

interface UseTasksPickerReturn {
  options: AvailabeTaskOption[];
  selectedOptions: AvailabeTaskOption[];
  handleSelectOptions: (selectedOptions: Option[]) => void;
  handleSearchTasks: (name: string) => void;
}

interface TasksMap {
  [taskId: string]: LinkedTask;
}

const useTasksPicker: (
  params: UseTasksPickerParams
) => UseTasksPickerReturn = ({ onSelectTasks, onSearchTasks }) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [availableTasks, setAvailableTasks] = useState<TasksMap>({});

  const serializeOnSearchTasksResponse = useCallback(
    (response: FetchAvailableTasksResponse) => {
      const { availableTasks = [], permissions = {} } = response;
      return availableTasks.reduce((acc: TasksMap, cur: LinkedTask) => {
        return {
          ...acc,
          [cur.id]: {
            ...cur,
            disabled: !permissions[cur.id]?.canEdit ?? false,
          },
        };
      }, {});
    },
    []
  );

  const handleSearchTasks = useCallback(
    async (name: string) => {
      const response = await onSearchTasks(name);
      const tasksMap = serializeOnSearchTasksResponse(response);
      setAvailableTasks((prev) => ({
        ...prev,
        ...tasksMap,
      }));
    },
    [onSearchTasks, serializeOnSearchTasksResponse]
  );

  useEffect(() => {
    handleSearchTasks('');
  }, [handleSearchTasks]);

  const options = useMemo(
    () =>
      Object.values(availableTasks).map((task: LinkedTask) => ({
        value: task.id,
        label: `${task.displayId} - ${task.name}`,
        disabled: task.disabled,
      })),
    [availableTasks]
  );

  const handleSelectOptions = (selectedOptions: AvailabeTaskOption[]) => {
    const taskIds = selectedOptions.map(
      (option: AvailabeTaskOption) => option.value
    );
    onSelectTasks(taskIds);
    setSelectedOptions(selectedOptions);
  };

  return {
    options,
    selectedOptions,
    handleSelectOptions,
    handleSearchTasks,
  };
};

export default useTasksPicker;
