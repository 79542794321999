export const validateIfLinkedTaskDueDateIsPast = (
  dueDate?: string,
  isTaskCompleted?: boolean
) => {
  if (dueDate && !isTaskCompleted) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dueDateObj = new Date(dueDate);
    dueDateObj.setHours(0, 0, 0, 0);

    return dueDateObj.getTime() < today.getTime();
  }
  return false;
};

export const validateIfLinkedTaskDatesOverlap = (
  predecessorDueDate: string,
  successorStartDate: string
) => {
  if (predecessorDueDate && successorStartDate) {
    const predecessorDueDateObj = new Date(predecessorDueDate);
    predecessorDueDateObj.setHours(0, 0, 0, 0);
    const successorStartDateObj = new Date(successorStartDate);
    successorStartDateObj.setHours(0, 0, 0, 0);

    return predecessorDueDateObj.getTime() > successorStartDateObj.getTime();
  }
  return false;
};
