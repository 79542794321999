import { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import {
  fetchPredecessorsDependencies,
  fetchSuccessorsDependencies,
  selectIsFetchingDependencies,
  selectHasTaskDependencies,
  selectShouldRefresh,
  setShouldRefresh,
} from 'state/TaskDependencies/TaskDependenciesSlice';
import SkeletonLoader from './SkeletonLoader';
import Predecessors from './Predecessors/Predecessors';
import Successors from './Successors/Successors';
import AddDependencies from './AddDependencies/AddDependencies';
import ActionsNotifications from './ActionsNotifications/ActionsNotifications';
import RemoveDependencyConfirmation from './RemoveDependencyConfirmation/RemoveDependencyConfirmation';

interface DependenciesProps {
  projectId: string;
  taskId: string;
  taskDisplayId: string;
  canAddDependencies?: boolean;
}

const Dependencies: FC<DependenciesProps> = ({
  taskId,
  taskDisplayId,
  projectId,
  canAddDependencies = true,
}) => {
  const dispatch = useDispatch();
  const shouldRefresh = useSelector(selectShouldRefresh);
  const isFetchingDependencies = useSelector(selectIsFetchingDependencies);
  const hasTaskDependencies = useSelector(selectHasTaskDependencies);

  const fetchDependencies = useCallback(() => {
    dispatch(fetchPredecessorsDependencies(taskId));
    dispatch(fetchSuccessorsDependencies(taskId));
  }, [dispatch, taskId]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchDependencies();
      dispatch(setShouldRefresh(false));
    }
  }, [shouldRefresh, dispatch, fetchDependencies]);

  useEffect(() => {
    fetchDependencies();
  }, [fetchDependencies]);

  return (
    <div
      className={tailwindOverride(
        'w-full h-auto',
        'flex flex-col gap-y-6',
        'border-b border-neutral-lighter',
        'mt-8 pb-10'
      )}
    >
      <Typography variant='h5' className='text-primary'>
        {intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.TITLE')}
      </Typography>
      {isFetchingDependencies && <SkeletonLoader />}
      {!isFetchingDependencies && canAddDependencies && (
        <AddDependencies taskId={taskId} projectId={projectId} />
      )}
      {!isFetchingDependencies && !hasTaskDependencies && (
        <Typography variant='label'>
          {intl.get(
            'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.NO_DEPENDENCIES_ADDED'
          )}
        </Typography>
      )}
      {!isFetchingDependencies && hasTaskDependencies && (
        <>
          <RemoveDependencyConfirmation taskId={taskId} />
          <Predecessors projectId={projectId} />
          <Successors projectId={projectId} />
        </>
      )}
      <ActionsNotifications currentTask={taskDisplayId} />
    </div>
  );
};

export default Dependencies;
