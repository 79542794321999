import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Request } from 'types/store/normalized';
import { Status } from 'utils/customTypes';
import { RequestStatus } from 'utils/types/request';
import { SLICE_STATUS } from 'utils/constants';
import { REQUEST_STATUSES } from 'utils/constants/request';
import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'utils/constants/ui';

/* ============================== STATE SETUP =============================== */
interface RequestsState {
  status: Status;
  limit: number;
  offset: number;
}

const initialState: RequestsState = {
  status: SLICE_STATUS.IDLE,
  limit: DEFAULT_PAGE_SIZE,
  offset: DEFAULT_OFFSET,
};

const requestsAdapter = createEntityAdapter<Request>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.requests;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  pageSize?: number;
  offset?: number;
  status?: readonly RequestStatus[];
}
export const getRequests = createAsyncThunk<Request[], GetListParams, ThunkApi>(
  'normalized/requests/getRequests',
  async (
    {
      pageSize = DEFAULT_PAGE_SIZE,
      offset = DEFAULT_OFFSET,
      status = Object.values(REQUEST_STATUSES),
    },
    { extra: { getRequestsApi } }
  ) => {
    const requests = await getRequestsApi().getList({
      params: {
        pageSize,
        offset,
        'filter[status]': status.join(','),
      },
    });
    return requests;
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const requestsSlice = createSlice({
  name: 'normalized/requests',
  initialState: requestsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getRequests.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getRequests.fulfilled, (state, action) => {
        requestsAdapter.upsertMany(state, action.payload);
        state.ids = action.payload.map((request) => request.id);
        if (action.meta.arg.pageSize) {
          state.limit = action.meta.arg.pageSize;
        }
        if (action.meta.arg.offset) {
          state.offset = action.meta.arg.offset;
        }
        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = requestsAdapter.getSelectors<RootState>(
  (state) => state.normalized.requests
);

const selectPageNumber = (state: RootState) =>
  state.normalized.requests.offset / state.normalized.requests.limit + 1;

const selectStatus = (state: RootState) => state.normalized.requests.status;

export const requestsSelectors = {
  ...defaultSelectors,
  selectPageNumber,
  selectStatus,
};

export default requestsSlice.reducer;
