import { FC } from 'react';
import intl from 'react-intl-universal';
import { Icon, tailwindOverride, Tag } from '@getsynapse/design-system';
import TruncatedCardTitle from '../TruncatedCardTitle/TruncatedCardTitle';
import open from 'assets/icons/open.svg';

interface CardTitleLinkProps {
  taskName: string;
  taskDisplayId: string;
  route: string;
  disabled?: boolean;
}

const CardTitleLink: FC<CardTitleLinkProps> = ({
  taskName,
  taskDisplayId,
  route,
  disabled,
}) => {
  const shouldTruncateTitle = taskName.length > 60;
  let truncatedTitle = taskName;

  if (shouldTruncateTitle) {
    truncatedTitle = `${taskName.slice(0, 60)}...`;
  }

  return (
    <div className='flex gap-x-2 items-center group'>
      {shouldTruncateTitle && (
        <TruncatedCardTitle
          trigger={
            <a
              target='_blank'
              rel='noopener noreferrer'
              className={tailwindOverride(
                'text-primary text-base',
                'group-hover:text-purple-darker',
                'group-hover:font-semibold'
              )}
              href={route}
            >
              {`${taskDisplayId} - ${truncatedTitle}`}
            </a>
          }
          fullTitle={`${taskDisplayId} - ${taskName}`}
        />
      )}
      {!shouldTruncateTitle && (
        <a
          target='_blank'
          rel='noopener noreferrer'
          className={tailwindOverride(
            'text-primary text-base',
            'group-hover:text-purple-darker',
            'group-hover:font-semibold'
          )}
          href={route}
        >
          {`${taskDisplayId} - ${truncatedTitle}`}
        </a>
      )}
      <Icon
        src={open}
        aria-label='open-task-icon'
        className={tailwindOverride(
          'text-xl -mt-0.5 hidden',
          'fill-current text-purple-darker',
          'group-hover:block'
        )}
      />
      {disabled && (
        <Tag
          label={intl.get('DISABLED')}
          className='bg-neutral-lighter-two text-neutral-darker'
        />
      )}
    </div>
  );
};

export default CardTitleLink;
