import { FC, ReactNode } from 'react';
import { Tooltip, Typography } from '@getsynapse/design-system';

interface TruncatedCardTitleProps {
  fullTitle: string;
  trigger: ReactNode;
}

const TruncatedCardTitle: FC<TruncatedCardTitleProps> = ({
  fullTitle,
  trigger,
}) => {
  return (
    <Tooltip
      openMode='hover2'
      timeout={0}
      usePortal
      showPopper
      trigger={<div>{trigger}</div>}
      contentProps={{
        className: 'bg-neutral-darker z-50 px-2 py-1',
      }}
    >
      <Typography variant='label' className='text-neutral-white'>
        {fullTitle}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedCardTitle;
