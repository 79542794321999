import { useMemo, useState, useEffect, useCallback } from 'react';
import { NewTask } from 'types/store/newTask';
import { difference } from 'utils/functions';

interface TaskFormFieldsProps {
  orginalTaskData?: NewTask;
  isTaskTemplate?: boolean;
  isDuplicate?: boolean;
}

interface UseTaskFormResponse {
  taskData: NewTask;
  canSaveTask: boolean;
  updateTaskData: (key: keyof NewTask, value: NewTask[keyof NewTask]) => void;
  setCanSaveTask: React.Dispatch<React.SetStateAction<boolean>>;
  hasUpdatedValues: boolean;
  resetTaskForm: () => void;
}

const useTaskForm = ({
  orginalTaskData,
  isTaskTemplate = false,
  isDuplicate = false,
}: TaskFormFieldsProps): UseTaskFormResponse => {
  const [taskData, setTaskData] = useState<NewTask>(
    () => orginalTaskData || ({} as NewTask)
  );
  const [canSaveTask, setCanSaveTask] = useState<boolean>(false);

  useEffect(() => {
    if (isDuplicate) {
      setTaskData(orginalTaskData || ({} as NewTask));
    }
  }, [isDuplicate, orginalTaskData]);

  const updateTaskData = useCallback(
    (key: keyof NewTask, value: NewTask[keyof NewTask]) => {
      setTaskData((prevData: NewTask) => ({
        ...prevData,
        [key]: value,
      }));
    },
    []
  );

  const resetTaskForm = useCallback(() => {
    setTaskData(orginalTaskData || ({} as NewTask));
    setCanSaveTask(false);
  }, [orginalTaskData]);

  const hasAssingees = taskData.assignees
    ? taskData.assignees.length > 0
    : false;

  const hasAddedRequiredValues = useMemo<boolean>(() => {
    return Boolean(
      ((hasAssingees && taskData.startDate && taskData.dueDate) ||
        !hasAssingees) &&
        taskData.name &&
        (isTaskTemplate || taskData.projectId)
    );
  }, [
    taskData.startDate,
    taskData.dueDate,
    taskData.name,
    taskData.projectId,
    isTaskTemplate,
    hasAssingees,
  ]);

  const hasUpdatedValues = useMemo<boolean>(() => {
    const assigneesDiff: string[] = difference(
      orginalTaskData?.assignees || [],
      taskData.assignees || []
    );
    return Boolean(
      orginalTaskData?.name !== taskData.name ||
        orginalTaskData?.type !== taskData.type ||
        orginalTaskData?.description !== taskData.description ||
        orginalTaskData?.estimateHours !== taskData.estimateHours ||
        orginalTaskData?.startDate !== taskData.startDate ||
        orginalTaskData?.dueDate !== taskData.dueDate ||
        orginalTaskData?.projectId !== taskData.projectId ||
        assigneesDiff.length > 0
    );
  }, [orginalTaskData, taskData]);

  useEffect(() => {
    if (hasAddedRequiredValues) {
      if (hasUpdatedValues) {
        !canSaveTask && setCanSaveTask(true);
      }
    } else {
      canSaveTask && setCanSaveTask(false);
    }
  }, [canSaveTask, hasAddedRequiredValues, hasUpdatedValues]);

  return useMemo(
    () => ({
      taskData,
      canSaveTask,
      updateTaskData,
      setCanSaveTask,
      hasUpdatedValues,
      resetTaskForm,
    }),
    [
      taskData,
      canSaveTask,
      updateTaskData,
      setCanSaveTask,
      hasUpdatedValues,
      resetTaskForm,
    ]
  );
};

export default useTaskForm;
