import { ACTIVE_REQUEST_STATUSES } from 'utils/constants/request';
import RequestsTable from 'Organisms/RequestsTable/RequestsTable';
import ServerPagination from 'Organisms/Pagination/ServerPagination';
import useRequestsTable from 'Hooks/Requests/useRequestsTable';

const ActiveRequestsTable = () => {
  const { requests, pageNumber, loading, onPageChange } = useRequestsTable(
    ACTIVE_REQUEST_STATUSES
  );

  return (
    <>
      <div className='w-full rounded-b max-h-small-table overflow-auto border border-neutral-lighter-two'>
        <RequestsTable requests={requests} loading={loading} />
      </div>
      <ServerPagination
        total={requests.length}
        onChange={onPageChange}
        page={pageNumber}
      />
    </>
  );
};

export default ActiveRequestsTable;
