import React, { useState, useMemo } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Table, Typography, Button, Icon } from '@getsynapse/design-system';
import { ProjectContent, SortingType, Task } from 'utils/customTypes';
import {
  DATE,
  PROJECT_CONTENT_TYPE,
  PROJECT_CONTENT_TABLE_SORTING,
  USER_TYPES,
} from 'utils/constants';
import {
  getContentTypeLabel,
  getLinkedTasksList,
  getUserAvatar,
  getClickableFileName,
  sortFiles,
} from './utils';
import EmptyTable from 'assets/images/empty-content-table.svg';
import Thumbnail from '../Thumbnail/Thumbnail';
import ActionsMenu from './components/ActionsMenu';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import useGetProjectReadOnly from 'Pages/ProjectPage/hooks/useGetProjectReadOnly';
import Pagination from 'Organisms/Pagination/ClientPagination';
import ConfirmationModal from './components/ConfirmationModal';
import LinkUnlinkToTaskModal from './components/LinkUnlinkToTaskModal';
import ReplaceContentModal from './components/ReplaceContentModal/ReplaceContentModal';
import { useSelector } from 'react-redux';
import { getCurrentProjectData } from 'state/Project/projectSlice';
import { selectUserType, selectUserId } from 'state/User/userSlice';

export type OrderByValues =
  typeof PROJECT_CONTENT_TABLE_SORTING[keyof typeof PROJECT_CONTENT_TABLE_SORTING];

const CONTENT_FILE_ACTIONS = {
  DELETE: 'DELETE',
  REPLACE: 'REPLACE',
  LINK_UNLINK_TASK: 'LINK_UNLINK_TASK',
} as const;

type ContentFileActions =
  typeof CONTENT_FILE_ACTIONS[keyof typeof CONTENT_FILE_ACTIONS];

type ProjectFilesTableProps = {
  projectId: string;
  files: ProjectContent[];
  onRemoveFile: (file: ProjectContent) => void;
  onAddContent: () => void;
  canRemoveFile?: boolean;
  canAddFile?: boolean;
};

const FileNameColumn: React.FC<{ file: ProjectContent }> = ({ file }) => {
  const [displayIcon, setDisplayIcon] = useState(false);
  const { children = null, href = '' } = getClickableFileName(file);

  return (
    <div
      className='h-full flex items-center w-full'
      onMouseOver={() => setDisplayIcon(true)}
      onMouseLeave={() => setDisplayIcon(false)}
    >
      <div className='truncate'>
        <a
          href={href}
          target='_blank'
          className={classnames('text-purple-darker', {
            underline: displayIcon,
          })}
          rel='noreferrer'
          title={file.name}
        >
          {children}
        </a>
      </div>
      <div>
        <Icon
          name='open-outline'
          className={classnames(
            'ml-2 text-base font-bold',
            `${displayIcon ? 'opacity-100' : 'opacity-0'}`
          )}
        />
      </div>
    </div>
  );
};

const ProjectFilesTable: React.FC<ProjectFilesTableProps> = ({
  projectId,
  files,
  onRemoveFile,
  onAddContent,
  canRemoveFile = true,
}) => {
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({
    limit: 15,
    offset: 0,
  });
  const [sorting, setSorting] = useState<{
    orderBy: OrderByValues | null;
    order: SortingType;
  }>({ order: 'asc', orderBy: null });
  const [fileToUpdate, setFileToUpdate] = useState<ProjectContent | null>(null);
  const [updateAction, setUpdateAction] = useState<ContentFileActions | null>(
    null
  );

  const handleSort = (orderByParam: string, order: SortingType) => {
    setSorting({
      orderBy: orderByParam as OrderByValues,
      order,
    });
  };

  const handlePagination = (newPagination: object) => {
    if (!isEqual(newPagination, pagination)) {
      setPagination({
        limit: get(newPagination, 'limit', 15),
        offset: get(newPagination, 'offset', 0),
      });
    }
  };

  const sortedRows = useMemo(() => {
    const sortedFiles = sortFiles(files, sorting);
    return sortedFiles.slice(pagination.offset, pagination.limit);
  }, [files, sorting, pagination]);

  const projectData = useSelector(getCurrentProjectData);

  const userType = useSelector(selectUserType);
  const currentUserId = useSelector(selectUserId);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;
  const isCurrentFacilitatorUserProjectOwner =
    isFacilitator && projectData.owners?.includes(currentUserId);

  const isReadOnly =
    useGetProjectReadOnly(projectData.status, projectData.is_archived) ||
    (isFacilitator && !isCurrentFacilitatorUserProjectOwner);

  return (
    <>
      <ConfirmationModal
        shouldDisplay={
          updateAction === CONTENT_FILE_ACTIONS.DELETE && fileToUpdate !== null
        }
        onCancel={() => setFileToUpdate(null)}
        onConfirm={() => {
          onRemoveFile(fileToUpdate!);
          setFileToUpdate(null);
        }}
        contentType={
          fileToUpdate?.content_type
            ? fileToUpdate.content_type
            : PROJECT_CONTENT_TYPE.FILE
        }
      />
      <LinkUnlinkToTaskModal
        projectId={projectId}
        contentFile={fileToUpdate}
        isModalOpen={
          updateAction === CONTENT_FILE_ACTIONS.LINK_UNLINK_TASK &&
          fileToUpdate !== null
        }
        onCloseModal={() => setFileToUpdate(null)}
      />
      <ReplaceContentModal
        projectId={projectId}
        contentFile={fileToUpdate}
        onCloseModal={() => setFileToUpdate(null)}
        isModalOpen={
          updateAction === CONTENT_FILE_ACTIONS.REPLACE && fileToUpdate !== null
        }
      />
      <div className='py-4 px-6 max-h-table overflow-y-hidden rounded'>
        <Table
          data-cy='content-table'
          canSelectRows={false}
          bodyProps={{
            className: classnames('max-h-request-details overflow-y-auto', {
              block: sortedRows.length > 0,
            }),
          }}
          data={{
            headData: {
              rowProps: {
                className: 'table w-full table-fixed',
              },
              stickyHeader: true,
              handleSort,
              headCells: [
                {
                  content: intl.get(
                    'PROJECT_DETAIL.FILES_TAB.TABLE.COLUMNS.FILE_THUMBNAIL'
                  ),
                  className: 'w-19 rounded-tl',
                  'data-cy': 'content-table__thumbnail-header',
                },
                {
                  content: intl.get(
                    'PROJECT_DETAIL.FILES_TAB.TABLE.COLUMNS.FILE_NAME'
                  ),
                  columnName: PROJECT_CONTENT_TABLE_SORTING.NAME,
                  'data-cy': 'content-table__name-header',
                  className: 'w-custom-column',
                },
                {
                  content: intl.get(
                    'PROJECT_DETAIL.FILES_TAB.TABLE.COLUMNS.FILE_TYPE'
                  ),
                  className: 'w-26',
                  'data-cy': 'content-table__content-type-header',
                },
                {
                  content: intl.get(
                    'PROJECT_DETAIL.FILES_TAB.TABLE.COLUMNS.UPLOADED_BY'
                  ),
                  columnName: PROJECT_CONTENT_TABLE_SORTING.ADDED_BY,
                  'data-cy': 'content-table__added-by-header',
                  className: 'w-56',
                },
                {
                  content: intl.get(
                    'PROJECT_DETAIL.FILES_TAB.TABLE.COLUMNS.UPLOADED_DATE'
                  ),
                  columnName: PROJECT_CONTENT_TABLE_SORTING.ADDED_ON,
                  className: 'w-32',
                  'data-cy': 'content-table__added-on-header',
                },
                {
                  content: intl.get(
                    'PROJECT_DETAIL.FILES_TAB.TABLE.COLUMNS.TASKS_LINKED'
                  ),
                  className: 'w-40',
                  'data-cy': 'content-table__tasks-linked-header',
                },
                {
                  content: <div></div>,
                  className: 'rounded-tr',
                },
              ],
            },
            rows: sortedRows.map((file: ProjectContent) => {
              return {
                key: file.id,
                className: 'cursor-pointer table w-full table-fixed',
                'data-cy': `content-table__file--${file.id}`,
                'data-id': file.id,
                cells: [
                  {
                    content: (
                      <Thumbnail
                        cloudFrontUrl={get(file, 'data.cloudFrontURL', '')}
                        backupUrl={get(file, 'data.url')}
                        mimeType={get(file, 'data.mimetype', '')}
                        contentType={file.content_type}
                      />
                    ),
                    className: 'w-19 p-0',
                    'data-cy': 'content-table__tasks-linked-column',
                  },
                  {
                    content: <FileNameColumn file={file} />,
                    'data-cy': 'content-table__name-column',
                    className: 'w-custom-column',
                  },
                  {
                    content: file.content_type && (
                      <Typography
                        variant='label'
                        className='text-neutral-black'
                        weight='medium'
                      >
                        {getContentTypeLabel(file.content_type)}
                      </Typography>
                    ),
                    className: 'font-semibold w-26',
                    'data-cy': 'content-table__content-type-column',
                  },
                  {
                    content: getUserAvatar(file),
                    className: 'font-semibold w-56',
                    'data-cy': 'content-table__added-by-column',
                  },
                  {
                    content:
                      file.createdAt &&
                      moment(new Date(file.createdAt)).format(
                        DATE.SHORT_FORMAT
                      ),
                    className: 'w-32',
                    'data-cy': 'content-table__added-on-column',
                  },
                  {
                    content: getLinkedTasksList(file.linkedTasks as Task[]),
                    className: 'w-40',
                    'data-cy': 'content-table__tasks-linked-column',
                  },
                  {
                    content: canRemoveFile ? (
                      <ActionsMenu
                        onRemoveCallback={() => {
                          setUpdateAction(CONTENT_FILE_ACTIONS.DELETE);
                          setFileToUpdate(file);
                        }}
                        onUpdateLinkedTasksCallback={() => {
                          setUpdateAction(
                            CONTENT_FILE_ACTIONS.LINK_UNLINK_TASK
                          );
                          setFileToUpdate(file);
                        }}
                        onReplaceCallback={() => {
                          setUpdateAction(CONTENT_FILE_ACTIONS.REPLACE);
                          setFileToUpdate(file);
                        }}
                        file={file}
                        isReadOnly={isReadOnly}
                      />
                    ) : null,
                  },
                ],
              };
            }),
          }}
          emptyComponent={
            <RenderNoRecords
              imageSrc={EmptyTable}
              caption={intl.get('PROJECT_DETAIL.FILES_TAB.NO_FILES')}
              className='h-empty-table-body'
              labelClassName='mt-0'
              imageClassName='ml-6'
              dataCy='content-table__empty-state'
            >
              <Button
                onClick={onAddContent}
                className='self-center mt-2'
                data-cy='add-content'
                disabled={isReadOnly}
              >
                {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.GET_STARTED')}
              </Button>
            </RenderNoRecords>
          }
        />
      </div>
      <Pagination
        total={files.length}
        onChange={handlePagination}
        className='z-10 max-w-full'
      />
    </>
  );
};

export default ProjectFilesTable;
