import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  Icon,
  Typography,
  tailwindOverride,
  Tooltip,
} from '@getsynapse/design-system';
import calendar from 'assets/icons/calendar.svg';
import overDue from 'assets/icons/task_due_age.svg';

interface DateRangeProps {
  startDate: string;
  dueDate: string;
  isTaskOverDue?: boolean;
  overlapingDates?: boolean;
}

export const dateFormater = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  year: 'numeric',
  day: 'numeric',
});

const formatDate = (date: string) => {
  if (!date) {
    return '';
  }
  return dateFormater.format(new Date(date));
};

const DateRange: FC<DateRangeProps> = ({
  startDate,
  dueDate,
  isTaskOverDue,
  overlapingDates,
}) => {
  if (!startDate || !dueDate) {
    return null;
  }
  return (
    <div className='flex gap-x-2 items-center'>
      {overlapingDates && (
        <Tooltip
          openMode='hover2'
          timeout={0}
          usePortal
          showPopper
          position='topCenter'
          contentProps={{
            className: 'bg-neutral-darker z-50 px-2 py-1',
          }}
          trigger={
            <div className='flex h-full items-center'>
              <Icon
                name='alert-circle'
                aria-label='overlapping-dates-icon'
                className='text-xl text-warning-darker'
              />
            </div>
          }
        >
          <Typography variant='label' className='text-neutral-white'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.OVERLAPPING_DATES')}
          </Typography>
        </Tooltip>
      )}
      <Icon
        src={isTaskOverDue ? overDue : calendar}
        aria-label={isTaskOverDue ? 'overdue-icon' : 'calendar-icon'}
        className={tailwindOverride('text-xl', {
          'fill-current text-error-dark': isTaskOverDue,
          'fill-current text-primary-darker': !isTaskOverDue,
        })}
      />
      <Typography
        variant='label'
        className={tailwindOverride('text-primary', {
          'text-error-dark': isTaskOverDue,
        })}
      >
        {formatDate(startDate)} - {formatDate(dueDate)}
      </Typography>
    </div>
  );
};

export default DateRange;
