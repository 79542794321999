import { useSelector } from 'react-redux';
import { Table } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

import { evaluationsSelectors } from 'state/Evaluations/evaluationsSlice';
import PerformitivRedirect from 'Atoms/PerformitivRedirect/PerformitivRedirect';

const EvaluationsTable = () => {
  const evaluations = useSelector(
    evaluationsSelectors.selectEvaluationsForTable
  );

  return (
    <div className='overflow-auto w-full'>
      <Table
        className='w-full relative'
        canSelectRows={false}
        data={{
          headData: {
            headCells: [
              {
                content: intl.get('EVALUATION.ATTRIBUTES.NAME'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.RELATIONSHIP'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.TAGS'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.PARTICIPANTS'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.RESPONSES'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.SCORES'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.TYPE'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.DATE'),
              },
              {
                content: intl.get('EVALUATION.ATTRIBUTES.FACILITATORS'),
              },
            ],
          },
          rows: evaluations.map((evaluation) => ({
            cells: [
              {
                content: (
                  <PerformitivRedirect evaluationId={evaluation.id}>
                    {evaluation.name}
                  </PerformitivRedirect>
                ),
                className: 'whitespace-normal min-w-25 max-w-66',
              },
              {
                content: evaluation.relationship,
                className: 'truncate min-w-25 max-w-46',
              },
              { content: evaluation.tags.length, className: 'truncate w-24' },
              {
                content: evaluation.participantCount,
                className: 'truncate w-24',
              },
              { content: evaluation.responseCount, className: 'truncate w-24' },
              {
                content: evaluation.score,
                className: 'truncate w-24',
              },
              {
                content: evaluation.type,
                className: 'truncate min-w-25 max-w-66',
              },
              {
                content: evaluation.date,
                className: 'truncate w-24',
              },
              {
                content: evaluation.instructors,
                className: 'truncate min-w-25 max-w-46',
              },
            ],
          })),
        }}
      />
    </div>
  );
};

export default EvaluationsTable;
