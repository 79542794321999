import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { createNewProject } from 'state/Projects/projectsSlice';
import { selectUser } from 'state/User/userSlice';
import { getOrganizationProcesses } from 'state/Processes/processesSlice';
import {
  getLDUsers,
  getFacilitatorUsers,
} from 'state/UsersManagement/usersManagementSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { updateRequestLinkedProjects } from 'state/ActiveRequest/activeRequestSlice';
import {
  fetchProjectTemplates,
  selectAllTemplates,
} from 'state/ProjectTemplates/projectTemplatesSlice';
import { PROJECT_PRIVACY, PATHS } from 'utils/constants';
import useModal from 'Hooks/useModal';
import ProjectCreationForm from 'Pages/ProjectsListPage/components/ProjectCreationForm';
import kanbanCardPlus from 'assets/icons/kanban-card-plus.svg';
import { NewProject } from '../../../utils/customTypes';
import { attachTaskBundleToProject } from 'state/TasksBundle/tasksBundleSlice';

const ProjectCreationModal = ({
  isOpen,
  setModalOpen,
  requestId,
}: {
  isOpen: boolean;
  setModalOpen: any;
  requestId: string;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectUser);
  const projectTemplates = useSelector(selectAllTemplates);
  const [fieldsValues, setFieldsValues] = useState({
    title: '',
    category_id: '',
    privacy: '',
    process_id: '',
    project_template_id: '',
    startDate: '',
    targetCompletionDate: '',
    owners: [currentUser.id],
    task_bundle_id: '',
  });
  const [learningTeamIds, setLearningTeamIds] = useState<string[]>([]);
  const { Modal, modalProps, openModal, closeModal } = useModal();

  useEffect(() => {
    const init = async () => {
      return await Promise.all([
        dispatch(getOrganizationProcesses()),
        dispatch(getLDUsers()),
        dispatch(getFacilitatorUsers()),
        dispatch(fetchProjectTemplates({ status: 'published' })),
      ]);
    };
    if (isOpen && !modalProps.isOpen) {
      init();
      openModal(modalProps);
    }
  }, [dispatch, isOpen, openModal, modalProps]);

  const updateValue = (newValue: any) =>
    setFieldsValues((prevData) => ({ ...prevData, ...newValue }));

  const [canCreateProject, setCanCreateProject] = useState(false);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    closeModal();
  }, [closeModal, setModalOpen]);

  const createProject = useCallback(
    async (redirectToProject?: boolean) => {
      const projectData: NewProject = {
        ...fieldsValues,
        projectRequests: [requestId],
      };
      const { task_bundle_id, ...newProjectData } = projectData;
      if (projectTemplates.length === 1) {
        newProjectData.project_template_id = projectTemplates[0].id;
      }
      if (fieldsValues.privacy === PROJECT_PRIVACY.TEAM) {
        newProjectData.learningTeams = learningTeamIds;
      }
      const createdProject = await dispatch(
        createNewProject({ ...newProjectData })
      );
      const newProjectId = get(createdProject, 'payload.data.id', '');
      handleCloseModal();

      if (newProjectId && task_bundle_id) {
        dispatch(
          attachTaskBundleToProject({
            projectId: newProjectId,
            taskBundleId: task_bundle_id,
          })
        );
      }

      if (newProjectId && redirectToProject) {
        history.push(`${PATHS.PROJECT_PAGE}/${newProjectId}`);
      } else {
        dispatch(
          updateRequestLinkedProjects(get(createdProject, 'payload.data'))
        );
      }
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get(
            'REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.SUCCESS_MESSAGE',
            {
              projectTitle: fieldsValues.title,
            }
          ),
        })
      );
    },
    [
      dispatch,
      fieldsValues,
      projectTemplates,
      history,
      requestId,
      handleCloseModal,
      learningTeamIds,
    ]
  );

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get(
          'REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.SAVE_OPEN'
        ),
        variant: 'primary',
        disabled: !canCreateProject,
        onClick: () => createProject(true),
        'data-cy': 'save-open-project',
      },
      {
        children: intl.get(
          'REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.SAVE_NOW'
        ),
        variant: 'secondary',
        disabled: !canCreateProject,
        onClick: () => createProject(),
        'data-cy': 'save-project-now',
      },
      {
        children: intl.get('REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'cancel-project-creation',
      },
    ],
    [canCreateProject, createProject, handleCloseModal]
  );

  const checkEmpty = (value: any) => {
    if (Array.isArray(value)) {
      return isEmpty(value);
    }
    return value == null || value === '';
  };

  useEffect(() => {
    const { task_bundle_id, ...originalRequiredFields } = fieldsValues;
    let requiredFields: Partial<NewProject> = { ...originalRequiredFields };
    if (projectTemplates.length === 1) {
      const { project_template_id, ...otherFields } = requiredFields;
      requiredFields = { ...otherFields };
    }
    if (
      requiredFields.privacy === PROJECT_PRIVACY.TEAM &&
      isEmpty(learningTeamIds)
    ) {
      canCreateProject && setCanCreateProject(false);
    } else if (
      !Object.values(requiredFields).some((value) => checkEmpty(value))
    ) {
      !canCreateProject && setCanCreateProject(true);
    } else {
      canCreateProject && setCanCreateProject(false);
    }
  }, [canCreateProject, fieldsValues, learningTeamIds, projectTemplates]);

  return (
    <Modal
      {...modalProps}
      title={intl.get('REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.TITLE')}
      titleIcon={{
        name: 'kanban-card-plus',
        src: kanbanCardPlus,
        'data-testid': 'request-create-project',
      }}
      aria-label={intl.get('REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.TITLE')}
      childrenClassName='max-h-full'
      size='large'
      closeModal={handleCloseModal}
      data-cy='create-project-modal'
      actionButtons={actionButtons}
    >
      <ProjectCreationForm
        description={intl.get('PROJECT_DETAIL.FILL_IN_INFORMATION_TITLE')}
        setLearningTeamIds={setLearningTeamIds}
        updateValue={updateValue}
        data={fieldsValues}
      />
    </Modal>
  );
};

export default ProjectCreationModal;
