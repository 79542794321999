import { FC, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import { Option } from 'utils/customTypes';
import {
  fetchAvailablePrograms,
  selectSearchPrograms,
  selectSearchProgramResult,
} from 'state/TasksList/Search/searchSlice';
import { RootState } from 'state/store';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';

interface ProgramFilterFieldProps {
  program: string[];
  onChange: (program: string[]) => void;
}

const ProgramFilterField: FC<ProgramFilterFieldProps> = ({
  program,
  onChange,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>('');
  const onFilterChange = useRef(
    debounce((name: string) => {
      dispatch(fetchAvailablePrograms({ name }));
    }, 500)
  );

  useEffect(() => {
    dispatch(fetchAvailablePrograms({}));
  }, [dispatch]);

  const programsList = useSelector((state: RootState) =>
    selectSearchPrograms(state, searchValue.length > 0)
  );
  const programsMap = useSelector(selectSearchProgramResult);

  const programOptions = useMemo<Option[]>(() => {
    const options = programsList?.map((program) => ({
      value: program.id,
      label: `${program.displayId} - ${program.name}`,
    }));
    return orderBy(options, 'label');
  }, [programsList]);

  const selectedProgramOptions = useMemo<Option[]>(
    () =>
      Object.values(programsMap)
        .filter((mappedProgram) => program.includes(mappedProgram.id))
        .map((program) => ({
          value: program.id,
          label: `${program.displayId} - ${program.name}`,
        })),
    [programsMap, program]
  );

  const handleOnChange = useCallback(
    (options: Option[]) => {
      onChange(options.map((option: Option) => option.value));
    },
    [onChange]
  );

  const handleFilterChange = useCallback(
    (name: string) => {
      setSearchValue(name);
      onFilterChange.current(name);
    },
    [onFilterChange]
  );

  return (
    <MultiSelectDropdown
      options={programOptions}
      filterable
      values={selectedProgramOptions}
      onFilterChange={handleFilterChange}
      onChange={handleOnChange}
      placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      triggerProps={{
        'aria-label': intl.get('ENTITIES.PROGRAM', { num: 1 }),
      }}
    />
  );
};

export default ProgramFilterField;
