import { FC, ReactNode } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { RelationshipType } from 'types/store/taskDependencies';
import { FetchAvailableTasksResponse } from 'api/taskDependencies';
import RelationshipTypePicker from './RelationshipTypePicker/RelationshipTypePicker';
import TasksPicker from './TasksPicker/TasksPicker';

interface AddDependencyFormProps {
  actionButtons?: ReactNode;
  className?: string;
  onSearchTasks: (name: string) => Promise<FetchAvailableTasksResponse>;
  setDependencyRelationship: (relationshipType: RelationshipType) => void;
  setDependencyTasks: (tasks: string[]) => void;
}

const AddDependencyForm: FC<AddDependencyFormProps> = ({
  actionButtons,
  onSearchTasks,
  setDependencyRelationship,
  setDependencyTasks,
  className,
}) => {
  return (
    <div
      className={tailwindOverride(
        'w-full flex items-center gap-x-4',
        className
      )}
    >
      <RelationshipTypePicker
        className='w-1/4'
        onSelectRelationshipType={setDependencyRelationship}
      />
      <TasksPicker
        onSelectTasks={setDependencyTasks}
        onSearchTasks={onSearchTasks}
        className='w-full'
      />
      {actionButtons}
    </div>
  );
};

export default AddDependencyForm;
