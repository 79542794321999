import { FC } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Tag, Typography } from '@getsynapse/design-system';
import TruncatedCardTitle from '../TruncatedCardTitle/TruncatedCardTitle';

interface CardTitleProps {
  taskName: string;
  taskDisplayId: string;
}

const CardTitle: FC<CardTitleProps> = ({ taskName, taskDisplayId }) => {
  const shouldTruncateTitle = taskName.length > 60;
  let truncatedTitle = taskName;

  if (shouldTruncateTitle) {
    truncatedTitle = `${taskName.slice(0, 60)}...`;
  }
  return (
    <div className='flex gap-x-2 items-center group'>
      {shouldTruncateTitle && (
        <TruncatedCardTitle
          fullTitle={`${taskDisplayId} - ${taskName}`}
          trigger={
            <Typography
              variant='body'
              className='text-primary'
            >{`${taskDisplayId} - ${truncatedTitle}`}</Typography>
          }
        />
      )}
      {!shouldTruncateTitle && (
        <Typography
          variant='body'
          className='text-primary'
        >{`${taskDisplayId} - ${truncatedTitle}`}</Typography>
      )}
      <Tag
        label={intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.NO_ACCESS_PERMISSION'
        )}
        className={tailwindOverride(
          'bg-neutral-lighter-two text-neutral-darker'
        )}
      />
    </div>
  );
};

export default CardTitle;
