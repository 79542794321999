const SkeletonLoader = () => {
  return (
    <div data-testid='strategy-goals_skeleton_loader'>
      <div className='animate-pulse w-full h-20 bg-primary-lighter-two mb-4' />
      <div className='animate-pulse w-full h-20 bg-primary-lighter-two' />
    </div>
  );
};

export default SkeletonLoader;
