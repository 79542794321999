import { useCallback, useState, ComponentProps } from 'react';
import { v4 } from 'uuid';
import { Snackbar, tailwindOverride } from '@getsynapse/design-system';

type SnackbarProps = ComponentProps<typeof Snackbar> & {
  id: string;
};

interface NotificationsMap {
  [id: string]: SnackbarProps;
}

const useSnackbarNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationsMap>({});

  const hideNotification = useCallback((id: string) => {
    setNotifications((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const showNotification = useCallback(
    (notificationProps: Omit<SnackbarProps, 'id'>) => {
      const id = v4();
      setNotifications((prev) => ({
        ...prev,
        [id]: {
          ...notificationProps,
          id: id,
          onCloseCallback: () => {
            notificationProps.onCloseCallback &&
              notificationProps.onCloseCallback();
            hideNotification(id);
          },
          className: tailwindOverride(
            'relative bg-neutral-white',
            'animate-snackarNotification',
            notificationProps.className
          ),
          autoHideDuration: notificationProps.autoHideDuration ?? 4000,
        },
      }));
    },
    [hideNotification]
  );

  return {
    notifications: Object.values(notifications),
    showNotification,
    hideNotification,
  };
};

export default useSnackbarNotifications;
