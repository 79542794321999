import { FC, useCallback } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import {
  selectTaskSuccessorsWithPermissions,
  setDependencyToRemove,
} from 'state/TaskDependencies/TaskDependenciesSlice';
import { LinkedTask, RelationshipType } from 'types/store/taskDependencies';
import { PATHS } from 'utils/constants';
import LinkedTaskCard from 'Organisms/TaskDependencies/LinkedTaskCard/LinkedTaskCard';

interface SuccessorsProps {
  projectId: string;
}

const Successors: FC<SuccessorsProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const successors = useSelector(selectTaskSuccessorsWithPermissions);
  const route = `${PATHS.PROJECT_PAGE}/${projectId}/tasks/`;

  const handleRemoveDependency = useCallback(
    (linkedTask: LinkedTask) => {
      dispatch(
        setDependencyToRemove({
          id: linkedTask.id,
          displayId: linkedTask.displayId,
          name: linkedTask.name,
          relationshipType: RelationshipType.Blocks,
        })
      );
    },
    [dispatch]
  );

  if (successors.length === 0) {
    return null;
  }

  return (
    <div className={tailwindOverride('w-full h-auto', 'flex flex-col gap-y-2')}>
      <Typography variant='caption' weight='medium' className='text-neutral'>
        {intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.BLOCKS')}
      </Typography>
      {successors.map(
        ({ permissions, isTaskOverDue, overlapingDates, ...linkedTask }) => (
          <LinkedTaskCard
            linkedTask={linkedTask}
            route={route + linkedTask.id}
            permissions={permissions}
            key={linkedTask.id}
            onRemoveDependency={handleRemoveDependency}
            isTaskOverDue={isTaskOverDue}
            overlapingDates={overlapingDates}
          />
        )
      )}
    </div>
  );
};

export default Successors;
