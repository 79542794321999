const SkeletonLoader = () => {
  return (
    <div
      className='w-full flex flex-col gap-y-6'
      aria-label='dependencies-skeleton-loader'
    >
      <div className='w-40 h-6 rounded bg-neutral-lighter-two' />
      <div className='w-full flex flex-col gap-y-2'>
        <div className='w-16 h-6 rounded bg-neutral-lighter-two' />
        <div className='w-full h-14 rounded bg-neutral-lighter-two' />
      </div>
      <div className='w-full flex flex-col gap-y-2'>
        <div className='w-16 h-6 rounded bg-neutral-lighter-two' />
        <div className='w-full h-14 rounded bg-neutral-lighter-two' />
      </div>
    </div>
  );
};

export default SkeletonLoader;
