import { RequestsApi } from './requests';

export class BaseApiFactory {
  private static requestsApi: RequestsApi;

  private constructor() {}

  static getRequestsApi(): RequestsApi {
    if (!this.requestsApi) {
      this.requestsApi = new RequestsApi();
    }
    return this.requestsApi;
  }
}
