import {
  Table,
  ProgressBar,
  Tooltip,
  IconButton,
  tailwindOverride,
} from '@getsynapse/design-system';
import moment from 'moment';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { ProgramLinkedProject, SortingType } from 'utils/customTypes';
import classnames from 'classnames';
import emptyProjectsTable from 'assets/icons/empty-projects.svg';
import { getUserAccessOnProject } from 'Pages/ProjectsListPage/helpers/getUserParticipantType';
import { isEmpty, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import UnlinkContent from 'assets/images/unlink-content.svg';
import { selectIsUserLd } from 'state/User/userSlice';
import { selectUserById } from 'state/UsersManagement/usersManagementSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  selectProgramProjectsTableSearch,
  selectProgramProjectsTableFilters,
  setProgramProjectsSortingOptions,
  selectProjectsForProgramSliceStatus,
  unlinkProjectFromProgram,
  getProgramId,
} from 'state/Program/programSlice';
import { setProjectId } from 'state/Project/projectSlice';
import TableEmptyErrorState from 'Molecules/TableEmptyErrorState/TableEmptyErrorState';
import noSearchResults from 'assets/images/no-projects-found-table.svg';
import noFilterResults from 'assets/images/empty-filtered-projects-table.svg';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import {
  DATE,
  PROGRAM_PROJECT_FIELDS,
  PATHS,
  SLICE_STATUS,
  PROJECT_STATUS,
} from 'utils/constants';
import { formatProjectNumber } from 'utils/formatters';
import { getStatusPill } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import ProjectSidePanel from 'Organisms/ProjectSidePanel/ProjectSidePanel';
import {
  decideCurrentProgressValueBasedOnProjectStatus,
  decideMaxValueBasedOnProjectStatus,
} from './ProgramProjectsTableHelpers';

const ProgramProjectsTable = ({
  projects,
  userHasWriteAccess = false,
}: {
  projects: ProgramLinkedProject[];
  userHasWriteAccess: boolean;
}) => {
  const handleSort = (orderByParam: string, order: SortingType) => {
    dispatch(setProgramProjectsSortingOptions(orderByParam, order));
  };

  const history = useHistory();
  const searchValue = useSelector(selectProgramProjectsTableSearch);
  const programId = useSelector(getProgramId);
  const filterValues = useSelector(selectProgramProjectsTableFilters);
  const projectsForProgramStatus = useSelector(
    selectProjectsForProgramSliceStatus
  );

  const userData = useSelector(selectUserById);
  const ldTeams = useSelector(selectLearningTeams);
  const isTableEmpty = projects.length === 0;
  const isLDUser = useSelector(selectIsUserLd);
  const dispatch = useDispatch();

  const unlinkProject = (projectId: string) => {
    dispatch(unlinkProjectFromProgram({ projectId }));
  };

  const onSelectProject = async (projectId: string) => {
    await dispatch(setProjectId(projectId));
  };

  return (
    <div
      className={tailwindOverride(
        'w-full transition-height',
        { 'border border-neutral-lighter-two': !isTableEmpty },
        { 'overflow-hidden': isTableEmpty }
      )}
    >
      <ProjectSidePanel onClosePanel={() => onSelectProject('')} />
      <div
        className={tailwindOverride('w-full', {
          'overflow-x-auto': !isTableEmpty,
          'overflow-hidden border-r border-neutral-lighter-two': isTableEmpty,
        })}
      >
        <Table
          className={classnames('relative w-full', {
            'max-w-full border-0 ': !isTableEmpty,
          })}
          data-cy='program-projects-table'
          canSelectRows={false}
          isSelectRowCellSticky
          data={{
            headData: {
              stickyHeader: true,
              handleSort,
              headCells: [
                {
                  columnName: PROGRAM_PROJECT_FIELDS.PROJECT_NUMBER,
                  content: intl.get(
                    'PROGRAM_PAGE.TABLE.COLUMNS.PROJECT_NUMBER'
                  ),
                  className: 'font-semibold w-24 truncate z-2',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.TITLE,
                  content: intl.get('PROGRAM_PAGE.TABLE.COLUMNS.TITLE'),
                  className: 'font-semibold max-w-60 min-w-60 left-0 z-2',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.STATUS,
                  content: intl.get('PROGRAM_PAGE.TABLE.COLUMNS.STATUS'),
                  className: 'font-semibold w-12 left-60 z-2',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.PRIORITY,
                  content: intl.get('PROGRAM_PAGE.TABLE.COLUMNS.PRIORITY'),
                  className: 'font-semibold w-30 py-3',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.PROGRESS,
                  content: intl.get('PROGRAM_PAGE.TABLE.COLUMNS.PROGRESS'),
                  className: 'font-semibold w-50 min-w-40 py-3',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.START_DATE,
                  content: intl.get('PROGRAM_PAGE.TABLE.COLUMNS.START_DATE'),
                  className: 'font-semibold w-30 py-3',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.TARGET_COMPLETION_DATE,
                  content: intl.get(
                    'PROGRAM_PAGE.TABLE.COLUMNS.TARGET_COMPLETION_DATE'
                  ),
                  className: 'font-semibold w-30 py-3',
                },
                {
                  columnName: PROGRAM_PROJECT_FIELDS.ACTUAL_COMPLETION_DATE,
                  content: intl.get(
                    'PROGRAM_PAGE.TABLE.COLUMNS.ACTUAL_COMPLETION_DATE'
                  ),
                  className: 'font-semibold w-30 py-3',
                },
                {
                  content: <div></div>,
                  className: classnames('h-10 bg-primary-lightest z-2', {
                    'w-16 right-0': !isTableEmpty,
                  }),
                },
              ],
            },
            rows: projects.map(
              (project: ProgramLinkedProject, index: number) => {
                const {
                  number_of_completed_tasks: completedTasksCount,
                  number_of_not_completed_tasks: incompletedTasksCount,
                } = project;

                const isOdd = index % 2 !== 0;

                const { isReadOnly, hasAccess } = getUserAccessOnProject(
                  project,
                  userData,
                  ldTeams
                );

                const stickyCellsStyles = (className: string) =>
                  classnames(
                    'border-transparent',
                    {
                      'sticky z-1': !isTableEmpty,
                      'bg-neutral-white': !isOdd,
                      'bg-neutral-lightest-two': isOdd,
                    },
                    className
                  );

                return {
                  id: project.id,
                  'data-cy': `linked-project-${project.id}`,
                  onClick: () => {
                    if (hasAccess) {
                      history.push(`${PATHS.PROJECT_PAGE}/${project.id}`, {
                        from: `${PATHS.PROGRAM_PAGE}/${programId}`,
                      });
                    } else {
                      dispatch(
                        showNotificationBanner({
                          notificationVariant: 'error',
                          notificationText: intl.get(
                            'PROGRAM_PAGE.PROJECT_NO_ACCESS'
                          ),
                        })
                      );
                    }
                  },
                  className: 'cursor-pointer w-full',
                  cells: [
                    {
                      content: (
                        <span title={project.projectNumber?.toString()}>
                          {formatProjectNumber(
                            project.projectNumber ? project.projectNumber : 0
                          )}
                        </span>
                      ),
                      className: stickyCellsStyles('w-24 truncate'),
                    },
                    {
                      content: (
                        <span title={project.title}>{project.title}</span>
                      ),
                      className: stickyCellsStyles(
                        'w-full max-w-60 min-w-60 left-0 truncate'
                      ),
                    },
                    {
                      content: getStatusPill(project.status),
                      className: stickyCellsStyles('w-12 left-60'),
                    },
                    {
                      content: intl.get(
                        `PROJECT_DETAIL.PRIORITY_OPTIONS.${project.priority?.toUpperCase()}`
                      ),
                      className: 'w-30',
                    },
                    {
                      content: (
                        <ProgressBar
                          maxValue={decideMaxValueBasedOnProjectStatus(
                            project.status,
                            completedTasksCount,
                            incompletedTasksCount
                          )}
                          currentValue={decideCurrentProgressValueBasedOnProjectStatus(
                            project.status,
                            completedTasksCount
                          )}
                          barClassName='shadow-inner h-2'
                          containerClassName='w-full'
                          progressClassName='bg-success-darker h-2'
                          percentageNumberClassName='text-neutral-black bg-transparent text-xs'
                        />
                      ),
                      className: 'w-50 min-w-40',
                    },
                    {
                      content: moment(new Date(project.startDate)).format(
                        DATE.SHORT_FORMAT
                      ),
                      className: 'truncate w-30',
                    },
                    {
                      content: moment(
                        new Date(project.targetCompletionDate)
                      ).format(DATE.SHORT_FORMAT),
                      className: 'truncate w-30',
                    },
                    {
                      content:
                        project.status === PROJECT_STATUS.COMPLETED
                          ? moment(
                              new Date(get(project, 'actualDate')!)
                            ).format(DATE.SHORT_FORMAT)
                          : '-',
                      className: 'truncate w-30',
                    },
                    {
                      content: (
                        <div
                          className={classnames('h-full text-center relative', {
                            'w-16': !isTableEmpty,
                            'w-full': isTableEmpty,
                          })}
                          onClick={(
                            event: React.MouseEvent<HTMLInputElement>
                          ) => event.stopPropagation()}
                        >
                          {isLDUser && (
                            <div className='hidden group-hover:flex'>
                              {userHasWriteAccess && (
                                <Tooltip
                                  openMode='hover1'
                                  timeout={0}
                                  showPopper
                                  position='topCenter'
                                  contentProps={{
                                    className: 'px-3 py-2 text-sm z-50',
                                  }}
                                  usePortal
                                  trigger={
                                    <IconButton
                                      src={UnlinkContent}
                                      data-cy={`unlink-project-${project.id}`}
                                      iconClassname='pointer-events-none'
                                      className='text-base ml-2 text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                                      onClick={() => unlinkProject(project.id)}
                                    />
                                  }
                                >
                                  {intl.get('PROGRAM_PAGE.UNLINK')}
                                </Tooltip>
                              )}
                              {hasAccess && (
                                <Tooltip
                                  openMode='hover1'
                                  timeout={0}
                                  showPopper
                                  position='topCenter'
                                  contentProps={{
                                    className:
                                      'px-3 py-2 text-sm z-50 absolute',
                                  }}
                                  usePortal
                                  trigger={
                                    <IconButton
                                      name={
                                        !isReadOnly ? 'pencil-outline' : 'eye'
                                      }
                                      iconClassname='pointer-events-none'
                                      className='text-base text-neutral-dark ml-2 p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                                      onClick={() =>
                                        onSelectProject(project.id)
                                      }
                                    />
                                  }
                                >
                                  {!isReadOnly
                                    ? intl.get('SIDE_PANEL.QUICK_EDIT')
                                    : intl.get('SIDE_PANEL.QUICK_VIEW')}
                                </Tooltip>
                              )}
                            </div>
                          )}
                        </div>
                      ),
                      className: stickyCellsStyles(classnames('w-12 right-0')),
                    },
                  ],
                };
              }
            ),
          }}
          emptyComponent={
            projectsForProgramStatus === SLICE_STATUS.FAILED ? (
              <TableEmptyErrorState />
            ) : !isEmpty(searchValue) || !isEmpty(filterValues) ? (
              <RenderNoRecords
                imageSrc={
                  !isEmpty(searchValue) ? noSearchResults : noFilterResults
                }
                className={
                  !isEmpty(searchValue)
                    ? 'pr-48 h-empty-table-body'
                    : 'h-empty-filtered-table-body'
                }
                caption={intl.get('PROGRAM_PAGE.TABLE.NO_RECORDS')}
              />
            ) : (
              <RenderNoRecords
                dataCy='empty-projects-table'
                imageSrc={emptyProjectsTable}
                imageClassName='-ml-4'
                className='pr-48 h-empty-table-body'
                caption={intl.get('PROGRAM_PAGE.TABLE.NO_LINKED_PROJECTS')}
                labelClassName='mt-0'
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default ProgramProjectsTable;
