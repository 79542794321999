import { FC, useRef } from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import {
  Dropdown,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import useTasksPicker from './useTasksPicker';
import { Option } from 'utils/customTypes';
import { FetchAvailableTasksResponse } from 'api/taskDependencies';
import TasksPickerTrigger, {
  TasksPickerTriggerProps,
} from './TasksPickerTrigger';
import { MultipleOptionLI } from 'Pages/NewProjectPage/helpers/snippets';

interface TasksPickerProps {
  onSelectTasks: (tasks: string[]) => void;
  onSearchTasks: (name: string) => Promise<FetchAvailableTasksResponse>;
  className?: string;
}

interface AvailabeTaskOption extends Option {
  disabled?: boolean;
}

const TasksPicker: FC<TasksPickerProps> = ({
  onSelectTasks,
  onSearchTasks,
  className,
}) => {
  const { options, selectedOptions, handleSelectOptions, handleSearchTasks } =
    useTasksPicker({ onSelectTasks, onSearchTasks });

  const debouncedFilterChangeHandler = useRef(
    debounce((name: string) => {
      handleSearchTasks(name);
    }, 500)
  );

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.TASKS_PICKER_TITLE')}
      labelProps={{ required: true }}
      className={className}
    >
      <Dropdown
        multiple
        filterable
        className='w-full'
        placeholder={intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.TASKS_PICKER_PLACEHOLDER'
        )}
        triggerProps={{
          'aria-label': intl.get(
            'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.TASKS_PICKER_PLACEHOLDER'
          ),
        }}
        options={options}
        values={selectedOptions}
        onChange={handleSelectOptions}
        onFilterChange={debouncedFilterChangeHandler.current}
        renderTrigger={(props) => (
          <TasksPickerTrigger
            {...(props as Omit<TasksPickerTriggerProps, 'limitTags'>)}
            limitTags={2}
            placeholder={intl.get(
              'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.TASKS_PICKER_PLACEHOLDER'
            )}
          />
        )}
        renderOption={(
          option: AvailabeTaskOption,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => (
          <li
            {...otherProps}
            className={tailwindOverride('group', className, {
              'hover:bg-primary focus-visible:bg-primary': isSelected,
              'cursor-not-allowed': option.disabled,
            })}
          >
            <MultipleOptionLI
              label={option.label}
              isSelected={isSelected}
              selectOption={selectOption}
              disabled={option.disabled}
            />
          </li>
        )}
      />
    </FormItem>
  );
};

export default TasksPicker;
