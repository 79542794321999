import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Table } from '@getsynapse/design-system';
import { Request } from 'types/store/normalized';
import { getRequestRoute } from 'utils/dynamicRoutes';
import { formatDate, formatRequestLearningPriority } from 'utils/formatters';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import NoRequestsTable from 'Molecules/NoRequestsTable/NoRequestsTable';
import { selectIsUserLd, selectOrganizationId } from 'state/User/userSlice';

interface RequestsTableProps {
  requests: Request[];
  loading?: boolean;
}

const RequestsTable = ({ requests, loading = false }: RequestsTableProps) => {
  const history = useHistory();
  const organizationId = useSelector(selectOrganizationId)!;
  const isLearningUser = useSelector(selectIsUserLd);

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <Table
      className='w-full'
      canSelectRows={false}
      data={{
        headData: {
          stickyHeader: true,
          headCells: [
            {
              content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.REQUEST_NO'),
            },
            {
              content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.REQUEST_TITLE'),
            },
            {
              content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS'),
            },
            ...(isLearningUser
              ? [
                  {
                    content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.PRIORITY'),
                  },
                ]
              : []),
            {
              content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.CREATION_DATE'),
            },
            {
              content: intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE'
              ),
            },
            {
              content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE'),
            },
            {
              content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.UPDATE_DATE'),
            },
          ],
        },
        rows: requests.map((request) => ({
          className: 'cursor-pointer',
          onClick: () =>
            history.push(getRequestRoute(request.id, organizationId)),
          cells: [
            {
              content: request.number,
            },
            {
              content: (
                <div className='w-64 truncate'>
                  <Link
                    to={getRequestRoute(request.id, organizationId)}
                    title={request.title}
                  >
                    {request.title}
                  </Link>
                </div>
              ),
            },
            {
              content: <RequestStatusTag status={request.status} />,
            },
            ...(isLearningUser
              ? [
                  {
                    content: formatRequestLearningPriority(
                      request.learnAndDevelopmentPriority
                    ),
                  },
                ]
              : []),
            {
              content: formatDate(request.createdAt),
            },
            {
              content: formatDate(request.submittedAt),
            },
            {
              content: formatDate(request.decisionDate),
            },
            {
              content: formatDate(request.updatedAt),
            },
          ],
        })),
        total: requests.length,
      }}
      emptyComponent={<NoRequestsTable />}
    />
  );
};

export default RequestsTable;
