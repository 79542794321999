import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';
import { Radio, Typography } from '@getsynapse/design-system';
import { ProjectContent } from 'utils/customTypes';
import { PROJECT_CONTENT_MODAL_TAB } from 'utils/constants';
import { Option } from 'utils/customTypes';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { getDesigns } from 'state/Designs/designsSlice';
import useModal from 'Hooks/useModal';
import AddFilesSection from './components/AddFilesSection';
import AddWeblinkSection from './components/AddWeblinkSection';
import AddDesignSection from './components/AddDesignSection';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import LinkV1Tasks from './components/linkTasks/LinkV1Tasks';
import LinkV2Tasks from './components/linkTasks/LinkV2Tasks';

const AddContentModal = ({
  projectId,
  isOpen,
  setModalOpen,
  handleUploadOrLinkFiles,
  canLinkTasks = true,
}: {
  projectId: string;
  isOpen: boolean;
  setModalOpen: any;
  canLinkTasks?: boolean;
  tasksRefactorEnabled?: boolean;
  handleUploadOrLinkFiles: (
    files: ProjectContent[],
    successMessage: string
  ) => void;
}) => {
  const { newTaskListViews = false } = useFlags();
  const dispatch = useDispatch();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [currentTab, setCurrentTab] = useState<string>(
    PROJECT_CONTENT_MODAL_TAB.DESIGN
  );
  const [contentList, setContentList] = useState<ProjectContent[]>([]);
  const [tasks, setTasks] = useState<Option[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const LinkTasksComponent = newTaskListViews ? LinkV2Tasks : LinkV1Tasks;

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getDesigns());
  }, [dispatch]);

  useEffect(() => {
    if (contentList.length > 0 || tasks.length > 0) {
      setHasChanges(true);
    }
  }, [contentList, tasks]);

  useEffect(() => {
    if (isOpen && !modalProps.isOpen) {
      openModal(modalProps);
      resetData();
      setCurrentTab(PROJECT_CONTENT_MODAL_TAB.DESIGN);
    }
  }, [isOpen, openModal, modalProps]);

  const handleCloseModal = () => {
    setHasChanges(false);
    setModalOpen(false);
    closeModal();
  };

  const resetData = () => {
    setContentList([]);
    setTasks([]);
  };

  const onConfirm = () => {
    let successMessage = intl.get('PROJECT_DETAIL.FILES_TAB.FILE_LINK_SUCCESS');
    if (currentTab === PROJECT_CONTENT_MODAL_TAB.FILE) {
      successMessage = intl.get('PROJECT_DETAIL.FILES_TAB.FILE_UPLOAD_SUCCESS');
    }
    if (currentTab === PROJECT_CONTENT_MODAL_TAB.DESIGN) {
      successMessage = intl.get(
        'PROJECT_DETAIL.FILES_TAB.DESIGN_ADDED_SUCCESS'
      );
    }
    handleUploadOrLinkFiles(
      contentList.map((content) => {
        return {
          ...content,
          linkedTasksIds: tasks.map((option) => option.value),
        };
      }),
      successMessage
    );
    handleCloseModal();
  };

  const actionButtons = [
    {
      children: intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT'),
      variant: 'primary',
      disabled: isEmpty(contentList),
      onClick: onConfirm,
      'data-cy': 'add-content-confirm-button',
    },
    {
      children: intl.get('REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.CANCEL'),
      variant: 'tertiary',
      onClick: handleCloseModal,
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT')}
      aria-label={intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT')}
      childrenClassName='max-h-full'
      size='large'
      closeModal={() => {
        if (hasChanges) {
          setShowUnsavedChangesModal(true);
        } else {
          setShowUnsavedChangesModal(false);
          handleCloseModal();
        }
      }}
      actionButtons={actionButtons}
    >
      <div data-cy='add-content-modal'>
        <Typography
          variant='body2'
          weight='medium'
          className='text-neutral-black'
        >
          {intl.get('PROJECT_DETAIL.FILES_TAB.SELECT_CONTENT')}
        </Typography>
        <div className='flex mt-3 pl-3'>
          <Radio
            name={intl.get('PROJECT_DETAIL.FILES_TAB.DESIGN')}
            label={intl.get('PROJECT_DETAIL.FILES_TAB.DESIGN')}
            data-testid='add-content-modal-design-radio'
            value={PROJECT_CONTENT_MODAL_TAB.DESIGN}
            inputProps={{
              checked: currentTab === PROJECT_CONTENT_MODAL_TAB.DESIGN,
              onChange: () => {
                setCurrentTab(PROJECT_CONTENT_MODAL_TAB.DESIGN);
                resetData();
              },
            }}
          />
          <Radio
            name={intl.get('PROJECT_DETAIL.FILES_TAB.FILE')}
            label={intl.get('PROJECT_DETAIL.FILES_TAB.FILE')}
            data-testid='add-content-modal-file-radio'
            value={PROJECT_CONTENT_MODAL_TAB.FILE}
            className='ml-10'
            inputProps={{
              checked: currentTab === PROJECT_CONTENT_MODAL_TAB.FILE,
              onChange: () => {
                setCurrentTab(PROJECT_CONTENT_MODAL_TAB.FILE);
                resetData();
              },
            }}
          />
          <Radio
            name={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK')}
            label={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK')}
            data-testid='add-content-modal-weblink-radio'
            value={PROJECT_CONTENT_MODAL_TAB.WEB_LINK}
            className='ml-10'
            inputProps={{
              checked: currentTab === PROJECT_CONTENT_MODAL_TAB.WEB_LINK,
              onChange: () => {
                setCurrentTab(PROJECT_CONTENT_MODAL_TAB.WEB_LINK);
                resetData();
              },
            }}
          />
        </div>
        {currentTab === PROJECT_CONTENT_MODAL_TAB.DESIGN && (
          <AddDesignSection setFiles={setContentList} />
        )}
        {currentTab === PROJECT_CONTENT_MODAL_TAB.FILE && (
          <AddFilesSection files={contentList} setFiles={setContentList} />
        )}
        {currentTab === PROJECT_CONTENT_MODAL_TAB.WEB_LINK && (
          <AddWeblinkSection setContent={setContentList} />
        )}
        {canLinkTasks && (
          <>
            <div className='w-full mt-7 border-b border-primary-light'></div>
            <LinkTasksComponent
              projectId={projectId}
              selectedTasks={tasks}
              setSelectedTasks={setTasks}
            />
          </>
        )}
      </div>
      <UnsavedChangesModal
        isOpen={showUnsavedChangesModal}
        setIsOpen={setShowUnsavedChangesModal}
        onConfirm={() => handleCloseModal()}
      />
    </Modal>
  );
};
export default AddContentModal;
