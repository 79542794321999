import axios from 'axios';
import { api } from './api';
import {
  LinkedTask,
  LinkedTaskPermissions,
  RelationshipType,
  FailedDependency,
} from 'types/store/taskDependencies';

export interface FetchSuccessorsResponse {
  successors: LinkedTask[];
  permissions: {
    [taskId: string]: LinkedTaskPermissions;
  };
}

export const fetchSuccessors = async (taskId: string) => {
  const response = await api.get<FetchSuccessorsResponse>(
    `/v2/tasks/${taskId}/successors`
  );
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export interface FetchPredecessorsResponse {
  predecessors: LinkedTask[];
  permissions: {
    [taskId: string]: LinkedTaskPermissions;
  };
}

export const fetchPredecessors = async (taskId: string) => {
  const response = await api.get<FetchPredecessorsResponse>(
    `/v2/tasks/${taskId}/predecessors`
  );
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export interface FetchAvailableTasksParams {
  projectId: string;
  taskId?: string;
  taskName?: string;
}

export interface FetchAvailableTasksResponse {
  availableTasks: LinkedTask[];
  permissions?: {
    [taskId: string]: LinkedTaskPermissions;
  };
}

export const fetchAvailableTasks = async (
  params: FetchAvailableTasksParams
) => {
  let url = `/v2/tasks/${params.taskId}/available-tasks`;
  const urlParams = new URLSearchParams();

  if (params.taskName) {
    urlParams.append('taskName', params.taskName);
  }

  if (params.projectId) {
    urlParams.append('projectId', params.projectId);
  }

  const response = await api.get<FetchAvailableTasksResponse>(
    `${url}?${urlParams.toString()}`
  );
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export interface CreateTaskDependenciesParams {
  taskId: string;
  relationshipType: RelationshipType;
  dependencies: string[];
}

interface CreateTaskDependenciesResponse {
  failures: FailedDependency[];
  successes: LinkedTask[];
}

export const createTaskDependencies = async (
  params: CreateTaskDependenciesParams
) => {
  const url = `/v2/tasks/${params.taskId}/${params.relationshipType}`;
  const response = await api.post<CreateTaskDependenciesResponse>(url, {
    dependencies: params.dependencies,
  });
  return response.data;
};

export interface DeleteTaskDependenciesParams
  extends CreateTaskDependenciesParams {}

interface DeleteTaskDependenciesResponse
  extends CreateTaskDependenciesResponse {}

export const deleteTaskDependencies = async (
  params: DeleteTaskDependenciesParams
) => {
  const url = `/v2/tasks/${params.taskId}/${params.relationshipType}`;
  const response = await api.delete<DeleteTaskDependenciesResponse>(url, {
    data: {
      dependencies: params.dependencies,
    },
  });
  return response.data;
};
