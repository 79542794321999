import { FC } from 'react';
import { Tag, tailwindOverride } from '@getsynapse/design-system';
import { TaskStatus } from 'types/store/tasks';
import { getTaskStatusLabel, getTaskStatusTagStyles } from './helpers';

interface TaskStatusTagProps {
  status: TaskStatus;
  selected?: boolean;
}

const TaskStatusTag: FC<TaskStatusTagProps> = ({ status, selected }) => {
  const tagLabel = getTaskStatusLabel(status);
  const tagStyle = getTaskStatusTagStyles(status, selected);
  return (
    <Tag
      className={tailwindOverride(
        `text-xs border border-transparent py-0.5 ${tagStyle.className}`
      )}
      textClassName={tagStyle.textClassName}
      label={tagLabel}
    />
  );
};

export default TaskStatusTag;
